import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { officeTypes } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction, regions, villages}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-offices-form-name'} label={'Ady'} 
                value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                icon={'bi bi-keyboard'} />

            <InputWrapper error={errors?.type} id={'admin-offices-form-type'} label={'Edara derejesi'} key={'admin-villages-form-type'}>
                <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                    {
                        officeTypes.map((officeType)=>{
                            return (
                                <CheckableCard key={`admin-offices-type-card-${officeType.value}`} title={officeType.title} 
                                    isActive={form?.type == officeType.value} 
                                    onClick={()=>{
                                        setForm({...form, type:officeType.value, region:undefined, village:undefined});
                                    }}/>
                            )
                        })
                    }
                </div>
            </InputWrapper>
            {
                form?.type < 20 ?
                <InputWrapper error={errors?.region} id={'admin-offices-form-region'} label={'Welaýat'} key={'admin-villages-form-region'}>
                    <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                        {
                            Object.keys(regions).map((key)=>{
                                let region = regions[key];
                                return (
                                    <CheckableCard key={`admin-offices-region-card-${region.id}`} title={region.name} 
                                        isActive={form?.region == region.id} 
                                        onClick={()=>{
                                            setForm({...form, region:region.id});
                                        }}/>
                                )
                            })
                        }
                    </div>
                </InputWrapper>
                :
                null
            }
            {
                form?.type < 10 && form?.region ?
                <InputWrapper error={errors?.village} id={'admin-offices-form-village'} label={'Etrap'} key={'admin-villages-form-village'}>
                    <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                        {
                            Object.keys(villages).map((key)=>{
                                let village = villages[key];
                                if(village?.region == form?.region){
                                    return (
                                        <CheckableCard key={`admin-offices-village-card-${village.id}`} title={village.name} 
                                            isActive={form?.village == village.id} 
                                            onClick={()=>{
                                                setForm({...form, village:village.id});
                                            }}/>
                                    )
                                }
                            })
                        }
                    </div>
                </InputWrapper>
                :
                null
            }

        </form>
    )
}