import { BrowserRouter, Route, Routes } from "react-router-dom";
import Notifications from "./components/notification";
import { AuthContext } from "./context/AuthContext";
import { useAuth } from "./hooks/useAuth";
import AdminDashboard from "./pages/admin/dashboard/dashboard";
import AdminStatistics from "./pages/admin/statistics/statistics";
import AdminPersonified from "./pages/admin/personified/personified";
import AdminSettings from "./pages/admin/settings/settings";
import Login from "./pages/auth/login";
import PersonifiedCreate from "./pages/admin/personified/create";
import AdminReports from "./pages/admin/reports/reports";
import Library from "./pages/admin/library/library";
import Chat from "./pages/admin/chat/chat";
import Certificates from "./pages/admin/certificates/certificates";
import ConfirmLogin from "./pages/auth/confirm";
import AdminRoles from "./pages/admin/settings/roles/roles";
import AdminRegions from "./pages/admin/settings/regions/regions";
import AdminVillages from "./pages/admin/settings/villages/villages";
import AdminOffices from "./pages/admin/settings/offices/offices";
import AdminEpids from "./pages/admin/settings/epids/epids";
import AdminDiagnoses from "./pages/admin/settings/diagnoses/diagnoses";
import AdminEpidOptions from "./pages/admin/settings/epid-options/epid-options";
import AdminUsers from "./pages/admin/settings/users/users";
import AdminUsersCreate from "./pages/admin/settings/users/create";
import AdminUsersUpdate from "./pages/admin/settings/users/update";
import StatisticCreate from "./pages/admin/statistics/create";
import StatisticUpdate from "./pages/admin/statistics/update";
import AdminPatients from "./pages/admin/settings/patients/patients";
import BabyRegistration from "./pages/admin/baby-registration";
import AddBabyRegistration from "./pages/admin/baby-registration/create";
import Marriage from "./pages/admin/marriage";
import MarriageDocument from "./pages/admin/marriage/marriage-document";
import MarriageRegistration from "./pages/admin/marriage/marriage-registration";
import MarriageReports from "./pages/admin/marriage/marriage-reports";
import MarriageBroke from "./pages/admin/marriage-broke";
import MarriageBrokeDocument from "./pages/admin/marriage-broke/document";
import MarriageBrokeRegistration from "./pages/admin/marriage-broke/registration";
import BabyCare from "./pages/admin/baby-care";
import CommitParent from "./pages/admin/commit-parent";
import ChangeName from "./pages/admin/change-name";
import DieRegistration from "./pages/admin/die";
import PersonRegistration from "./pages/admin/person-registration";

function App() {
  const {login, logout, token, user, isReady} = useAuth();
  const isLogin = !!token;
  return (
    <AuthContext.Provider value={{login, logout, token, user, isReady, isLogin}}>
      <Notifications />
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<AdminDashboard/>}/>
          <Route exact path="/baby-registration" element={<BabyRegistration/>}/>
          <Route exact path="/add-baby-registration" element={<AddBabyRegistration/>}/>
          <Route exact path="/marriage" element={<Marriage/>}/>
          <Route exact path="/marriage-broke" element={<MarriageBroke/>}/>
          <Route exact path="/marriage-broke/document" element={<MarriageBrokeDocument/>}/>
          <Route exact path="/marriage-broke/registration" element={<MarriageBrokeRegistration/>}/>
          <Route exact path="/marriage-broke/certificate" element={<MarriageBrokeDocument/>}/>
          <Route exact path="/marriage-document" element={<MarriageDocument/>}/>
          <Route exact path="/marriage-registration" element={<MarriageRegistration/>}/>
          <Route exact path="/marriage-certificate" element={<MarriageRegistration/>}/>
          <Route exact path="/marriage-reports" element={<MarriageReports/>}/>
          <Route exact path="/baby-care" element={<BabyCare/>}/>
          <Route exact path="/commit-parent" element={<CommitParent/>}/>
          <Route exact path="/change-name" element={<ChangeName/>}/>
          <Route exact path="/die-registration" element={<DieRegistration/>}/>
          <Route exact path="/person-registration" element={<PersonRegistration/>}/>
          

          <Route exact path="/statistic" element={<AdminStatistics/>}/>
          <Route exact path="/statistic/create" element={<StatisticCreate/>}/>
          <Route exact path="/statistic/update/:id" element={<StatisticUpdate/>}/>
          <Route exact path="/personified" element={<AdminPersonified/>}/>
          <Route exact path="/personified/create" element={<PersonifiedCreate/>}/>
          <Route exact path="/reports" element={<AdminReports/>}/>
          <Route exact path="/library" element={<Library/>}/>
          <Route exact path="/messenger" element={<Chat/>}/>
          <Route exact path="/certificates" element={<Certificates/>}/>
          <Route exact path="/settings" element={<AdminSettings/>}/>
          <Route exact path="/settings/users" element={<AdminUsers/>}/>
          <Route exact path="/settings/users/create" element={<AdminUsersCreate/>}/>
          <Route exact path="/settings/users/update/:id" element={<AdminUsersUpdate/>}/>
          <Route exact path="/settings/roles" element={<AdminRoles/>}/>
          <Route exact path="/settings/regions" element={<AdminRegions/>}/>
          <Route exact path="/settings/villages" element={<AdminVillages/>}/>
          <Route exact path="/settings/offiсes" element={<AdminOffices/>}/>
          <Route exact path="/settings/epids" element={<AdminEpids/>}/>
          <Route exact path="/settings/diagnoses" element={<AdminDiagnoses/>}/>
          <Route exact path="/settings/patients" element={<AdminPatients/>}/>
          <Route exact path="/settings/epid-options" element={<AdminEpidOptions/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/confirm" element={<ConfirmLogin/>}/>
          <Route path="*" element={<AdminDashboard/>}/>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
