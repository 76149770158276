export default function MainElement(){
    return (
      <div class="row">

        <div class="col-md-6 mb-3">
          <div class="col-12">
            <label for="inputNanme4" class="form-label">Ady</label>
            <input type="text" class="form-control" id="inputNanme4"/>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="col-12">
            <label for="inputNanme4" class="form-label">Familiýasy</label>
            <input type="text" class="form-control" id="inputNanme4"/>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="col-12">
            <label for="inputNanme4" class="form-label">Atasynyň ady</label>
            <input type="text" class="form-control" id="inputNanme4"/>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <div class="col-12">
            <label for="inputNanme4" class="form-label">Doglan güni</label>
            <input type="date" class="form-control" id="inputNanme4"/>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="inputState" class="form-label">Doglan welaýaty</label>
          <select id="inputState" class="form-select">
            <option selected="">...</option>
            <option>...</option>
            <option>Aşgabat</option>
            <option>Ahal</option>
            <option>Balkan</option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="inputState" class="form-label">Doglan etraby</label>
          <select id="inputState" class="form-select">
            <option selected="">...</option>
            <option>...</option>
            <option>Aşgabat</option>
            <option>Ahal</option>
            <option>Balkan</option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="inputState" class="form-label">Jynsy</label>
          <div class="d-flex align-items-center justify-content-center">
            <div class="col-4 me-3">
              <div class="card p-2 border border-1 border-primary position-relative">
                <div class="d-grid">
                  <img src="/img/boy.png" alt="" style={{width: 70+'%'}} class="mx-auto"/>
                  <p class="text-center m-0 p-0 mt-2 text-primary">Erkek</p>
                </div>
                <i class="bi bi-check-circle-fill position-absolute top-0 start-0 m-2 text-primary fw-light"></i>
              </div>
            </div>
            <div class="col-4">
              <div class="card p-2 border border-1 border-dark position-relative">
                <div class="d-grid">
                  <img src="/img/girl.png" alt="" style={{width: 70+'%'}} class="mx-auto"/>
                  <p class="text-center m-0 p-0 mt-2 text-secondary">Aýal</p>
                </div>
                <i class="bi bi-circle position-absolute top-0 start-0 m-2 text-secondary fw-light"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="inputState" class="form-label">Dogluş ýagdaýy</label>
          <div class="d-flex align-items-center justify-content-center">
            <div class="col-4 me-3">
              <div class="card p-4 border border-1 border-primary position-relative">
                <div class="d-grid text-primary text-center">
                  <p class="text-center m-0 p-0 mt-2">Diri doglan</p>
                </div>
                <i class="bi bi-check-circle-fill position-absolute top-0 start-0 m-2 text-primary fw-light"></i>
              </div>
            </div>
            <div class="col-4">
              <div class="card p-4 border border-1 border-dark position-relative">
                <div class="d-grid text-center text-secondary">
                  <p class="text-center m-0 p-0 mt-2">Öli doglan</p>
                </div>
                <i class="bi bi-circle position-absolute top-0 start-0 m-2 text-secondary fw-light"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <label for="inputState" class="form-label">Ýazgy ýagdaýy</label>
          <div class="d-flex align-items-center justify-content-center">
            <div class="col-4 me-3">
              <div class="card p-4 border border-1 border-primary position-relative">
                <div class="d-grid text-primary text-center">
                  <span class="fw-bold fs-2">01</span>
                  <p class="text-center m-0 p-0 mt-2">Birilenji</p>
                </div>
                <i class="bi bi-check-circle-fill position-absolute top-0 start-0 m-2 text-primary fw-light"></i>
              </div>
            </div>
            <div class="col-4">
              <div class="card p-4 border border-1 border-dark position-relative">
                <div class="d-grid text-center text-secondary">
                  <span class="fw-bold fs-2">02</span>
                  <p class="text-center m-0 p-0 mt-2">Gaýtadan</p>
                </div>
                <i class="bi bi-circle position-absolute top-0 start-0 m-2 text-secondary fw-light"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}