import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
export default function Form({form, setForm, errors, saveFunction, regions}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-villages-form-name'} label={'Ady'} 
                value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                icon={'bi bi-keyboard'} />

            <InputBlock error={errors?.short_name} id={'admin-villages-form-shortname'} label={'Gysga ady'} 
                value={form?.short_name} setValue={(e)=>setForm({...form, short_name:e})}
                icon={'bi bi-keyboard'} />

            <InputWrapper error={errors?.region} id={'admin-villages-form-region'} label={'Welaýaty'} key={'admin-villages-form-region'}>
                <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                    {
                        Object.keys(regions).map((key)=>{
                            return (
                                <CheckableCard key={`admin-villages-region-card-${key}`} title={regions[key]?.name} 
                                    isActive={form?.region == key} 
                                    onClick={()=>{
                                        setForm({...form, region:key});
                                    }}/>
                            )
                        })
                    }
                </div>
            </InputWrapper> 
        </form>
    )
}