import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import RolesFilter from "./filter";
import { addZero, checkOnlyLetters, checkRequire, getString, handleErrors, officeTypesObj } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import Filter from "./filter";

export default function AdminOffices(){
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({name:''});
    const [errors, setErrors] = useState({name:null});
    const [filter, setFilter] = useState({page:1, limit:10, regions:[], types:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-offices-modal';
    const pageKey="admin-offices-page";
    const pageTitle="Edaralar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e))
        }
    }, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Edara derejesi" filter={filter} setFilter={setFilter} targetAttribute='type'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-primary text-primary ms-2 fw-bold">
                        {`${getString(officeTypesObj[item?.type])}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Welaýat" filter={filter} setFilter={setFilter} targetAttribute='region'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(regions[item?.region]?.short_name) ? getString(regions[item?.region]?.short_name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Etrap" filter={filter} setFilter={setFilter} targetAttribute='village'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(villages[item?.village]?.name) ? getString(villages[item?.village]?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDay())}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu etraby ýok etmekçimi?")){
                                AxiosHelper.deleteVillage(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Etrap üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getOffices(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, errors, setErrors, logout)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateOffice(form?.id, form, token) : AxiosHelper.createOffice(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Ýerli edara üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Ýerli edara ady boş bolup bilmez!'
        }else{
            err.name = null;
        }

        if(form?.type === null || form?.type === undefined){
            err.type='Ýerli edaranyň görnüşini saýlaň!'
        }else{
            err.type = null;
        }
        

        if(form?.type < 20){
            if(!checkRequire(form?.region)){
                err.region='Ýerli edaranyň haýsy welaýata degişlidigini saýlaň!'
             }else{
                 err.region = null;
             }
        }
        if(form?.type < 10){
            if(!checkRequire(form?.village)){
                err.village='Ýerli edaranyň haýsy etraba degişlidigini saýlaň!'
             }else{
                 err.village = null;
             }
        }
        setErrors({...errors, ...err})
        return err.name || err.short_name || err.region;
    }

    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={true} filterBlock={<Filter filter={filter} setFilter={setFilter} regions={regions} key={'admin-villages-filter'}/>}
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Ýerli edarany üýtgetmek' :"Ýerli edara goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({name:'', type:undefined, region:undefined, village:undefined}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save} regions={regions} villages={villages}/>
                </ModalWithButton>
            } />
    )
}