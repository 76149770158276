import { useState } from "react";
import AdminWrapper from "../../Wrapper";
import Form from "./form";

export default function AdminUsersCreate(){
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    return (
        <AdminWrapper>
            <Form errors={errors} setErrors={setErrors} form={form} setForm={setForm} isUpdate={false} key={'admin-users-form'} />
        </AdminWrapper>
    )
}