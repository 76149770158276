import { Link } from "react-router-dom";

export default function JumbotronButton({icon, title, link, ...props}){
    return (
        <Link to={link} className="h-100 text-primary rounded-3 d-flex align-items-center bg-light box-shadow text-decoration-none">
            <div className="col-md-4 d-flex align-items-center justify-content-center p-3">
                {icon}
            </div>
            <div className="col-md-8 bg-accent-primary h-100 rounded-2 d-flex align-items-center justify-content-center fs-5 fw-500">
                {title}
            </div>
        </Link>
    )
}