import FileDropzone from "../../../../components/dropzone/dropzone";

export default function ParentsElement(){
    return (
        <div className="row">
            <div className="col-md-6 border-end">
                <h4 className="text-slatgray">Ejesi</h4>
                <div className="row">
                    <div class="col-md-12 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Ady</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-12 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Familiýasy</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Atasynyň ady</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Doglan güni</label>
                            <input type="date" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Doglan welaýaty</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Aşgabat</option>
                            <option>Ahal</option>
                            <option>Balkan</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Doglan etraby</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Aşgabat</option>
                            <option>Ahal</option>
                            <option>Balkan</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Raýatlygy</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Türkmenistan</option>
                            <option>Türkiýe Resp.</option>
                            <option>Russiýa Fed.</option>
                            <option>Beýik Britaniýa</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Milleti</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Türkmen</option>
                            <option>Özbek</option>
                            <option>Azerbeýjan</option>
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label for="inputState" class="form-label mb-2">Şahsyýeti tassyklaýjy resminama</label>
                        <FileDropzone accept={''} maxFiles={-1} />
                    </div>
                </div>
            </div>

            <div className="col-md-6">
                <h4 className="text-slatgray">Kakasy</h4>
                <div className="row">
                    <div class="col-md-12 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Ady</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-12 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Familiýasy</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Atasynyň ady</label>
                            <input type="text" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <div class="col-12">
                            <label for="inputNanme4" class="form-label">Doglan güni</label>
                            <input type="date" class="form-control" id="inputNanme4"/>
                        </div>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Doglan welaýaty</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Aşgabat</option>
                            <option>Ahal</option>
                            <option>Balkan</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Doglan etraby</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Aşgabat</option>
                            <option>Ahal</option>
                            <option>Balkan</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Raýatlygy</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Türkmenistan</option>
                            <option>Türkiýe Resp.</option>
                            <option>Russiýa Fed.</option>
                            <option>Beýik Britaniýa</option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-2">
                        <label for="inputState" class="form-label">Milleti</label>
                        <select id="inputState" class="form-select">
                            <option selected="">...</option>
                            <option>Türkmen</option>
                            <option>Özbek</option>
                            <option>Azerbeýjan</option>
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label for="inputState" class="form-label mb-2">Şahsyýeti tassyklaýjy resminama</label>
                        <FileDropzone accept={''} maxFiles={-1} />
                    </div>
                </div>
            </div>
        </div>
    )
}