export default function AdminRightbar(){
    return(
        <aside className="rightbar">
            <div className="d-grid p-3">
                <div className="card p-3 box-shadow rounded-1 library-card mb-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span className="text-dark d-flex align-items-center">
                            <i className="bi bi-folder me-2"></i>
                            ERDU
                        </span> 
                        <div className="d-flex align-items-center">
                            <button className="btn btn-sm me-2">
                                <i className="bi bi-arrow-repeat"></i>
                            </button>
                            <span className="text-secondary">(7)</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="bg-accent-warning text-warning d-flex align-items-center justify-content-center rounded-2" style={{width:35+'px', height:35+'px'}}>
                            <i className="bi bi-envelope"></i>
                        </div>
                        <div className="d-grid text-small fw-500 ms-2">
                            <span className="text-dark">#1234 telegramma kabul edildi</span>
                            <span className="text-secondary">Aşgabat SES - 04.10.2023</span>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex align-items-center">
                        <div className="bg-accent-success text-success d-flex align-items-center justify-content-center rounded-2" style={{width:35+'px', height:35+'px'}}>
                            <i className="bi bi-hand-thumbs-up"></i>
                        </div>
                        <div className="d-grid text-small fw-500 ms-2">
                            <span className="text-dark">#2451 belgili habanamaňyz kabul edildi</span>
                            <span className="text-secondary">Mary SES - 02.10.2023</span>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex align-items-center">
                        <div className="bg-accent-danger text-danger d-flex align-items-center justify-content-center rounded-2" style={{width:35+'px', height:35+'px'}}>
                            <i className="bi bi-hand-thumbs-down"></i>
                        </div>
                        <div className="d-grid text-small fw-500 ms-2">
                            <span className="text-dark">#2450 belgili habanamaňyz ret edildi</span>
                            <span className="text-secondary">Mary SES - 29.09.2023</span>
                        </div>
                    </div>
                </div>

                <div className="card p-3 box-shadow rounded-1 library-card mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="text-dark">Kitaphana</span> 
                        <button className="btn">
                            <i className="bi bi-three-dots"></i>
                        </button>
                    </div>
                    <div className="d-grid">
                        <ul className="list-unstyled mb-0">
                            <li className="library-item mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="rounded-circle bg-accent-primary text-primary d-flex align-items-center justify-content-center me-2" 
                                        style={{width:50+'px', height:50+'px'}}>
                                            <i className="bi bi-journal-bookmark-fill fs-4"></i>
                                    </div>
                                    <span className="fs-6 fw-500">Gollanmalar</span>
                                    <span className="badge ms-auto bg-accent-primary text-secondary">13</span>
                                </div>
                            </li>
                            <li className="library-item mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="rounded-circle bg-accent-warning text-warning d-flex align-items-center justify-content-center me-2" 
                                        style={{width:50+'px', height:50+'px'}}>
                                            <i className="bi bi-journal-bookmark-fill fs-4"></i>
                                    </div>
                                    <span className="fs-6 fw-500">Kitaplar</span>
                                    <span className="badge ms-auto bg-accent-primary text-secondary">21</span>
                                </div>
                            </li>
                            <li className="library-item mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="rounded-circle bg-accent-danger text-danger d-flex align-items-center justify-content-center me-2" 
                                        style={{width:50+'px', height:50+'px'}}>
                                            <i className="bi bi-journal-bookmark-fill fs-4"></i>
                                    </div>
                                    <span className="fs-6 fw-500">Görkezmeler</span>
                                    <span className="badge ms-auto bg-accent-primary text-secondary">7</span>
                                </div>
                            </li>
                            <li className="library-item mb-2">
                                <div className="d-flex align-items-center">
                                    <div className="rounded-circle bg-accent-success text-success d-flex align-items-center justify-content-center me-2" 
                                        style={{width:50+'px', height:50+'px'}}>
                                            <i className="bi bi-journal-bookmark-fill fs-4"></i>
                                    </div>
                                    <span className="fs-6 fw-500">Buýruklar</span>
                                    <span className="badge ms-auto bg-accent-primary text-secondary">2</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="card p-3 box-shadow rounded-1 library-card">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="text-dark d-flex align-items-center">
                            <i className="bi bi-chat-square-dots me-2"></i>
                            Çat
                        </span> 
                        <div className="d-flex align-items-center">
                            <button className="btn btn-sm me-2">
                                <i className="bi bi-arrow-repeat"></i>
                            </button>
                            <span className="badge bg-accent-primary text-primary">25</span>
                        </div>
                    </div>
                    <hr className="my-2" />
                    <div className="d-flex align-items-center justify-content-start mb-2">
                        <div class="profile-item">
                            <div class="img-placeholder">
                                <span>
                                    Y
                                </span>
                            </div>
                            <div className="d-grid">
                                <span>Y.Babayew</span>
                                <small className="text-secondary">
                                    Mary SES.
                                </small>
                            </div>
                        </div>
                        <span className="ms-auto badge bg-accent-secondary text-secondary">2</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-start mb-2">
                        <div class="profile-item">
                            <div class="img-placeholder">
                                <span>
                                    A
                                </span>
                            </div>
                            <div className="d-grid">
                                <span>A.Jumaýew</span>
                                <small className="text-secondary">
                                    Aşgabat SES.
                                </small>
                            </div>
                        </div>
                        <span className="ms-auto badge bg-accent-secondary text-secondary">2</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-start">
                        <div class="profile-item">
                            <div class="img-placeholder">
                                <span>
                                    H
                                </span>
                            </div>
                            <div className="d-grid">
                                <span>H.Nazarow</span>
                                <small className="text-secondary">
                                    Daşoguz SES.
                                </small>
                            </div>
                        </div>
                        <span className="ms-auto badge bg-accent-secondary text-secondary">2</span>
                    </div>
                </div>
            </div>
        </aside>
    )
}