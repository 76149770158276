export default function Summary({count, filter}){
    let start = filter?.limit*(filter?.page-1)+1;
    let end = filter?.limit*filter?.page;
    if(end > count){
        end = count
    } 
    return (
        <div className="summary">
            {start}-{end} / {count}
        </div>
    )
}