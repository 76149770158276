import AutoSearchInput from "../../../../components/auto-search-input/input";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import Modal from "../../../../components/modal/modal";
import { getString } from "../../../../utils/main";
import SearchUser from "./search-user";
import CheckableCard from "../../../../components/checkable-card/checkable-card";
export default function Main({errors, setErrors, form, setForm, regions, villages, offices, officesArr}){
    const modalId = "user-search-modal";
    return (
        <div className="row">
            <div className="col-md-12">
                <InputWrapper error={errors?.patient}  id={'personified-record-patient'} label={"Raýat"}>
                    <button className="btn btn-light w-100 border text-start" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
                        {form?.patientData ? `${getString(form?.patientData?.surname)} ${getString(form?.patientData?.name)} ${getString(form?.patientData?.second_name)}` : '...'}
                    </button>
                    <Modal id={modalId} isFooter={false} isHeader={false} modalPosition="modal-end-bottom">
                        <SearchUser onDoubleClick={(e)=>setForm({...form, patient:e?.id, patientData:e})} />
                    </Modal>
                </InputWrapper> 
            </div>
            <div className="col-md-12">
                <InputWrapper error={errors?.region}  id={'personified-record-region'} label={"Welaýaty"}>
                    <div className="d-flex justify-content-center flex-wrap">
                        <CheckableCard title={'Döwlet derejesi'} isActive={form?.region == 0} onClick={()=>{
                                setForm({...form, region:0, village:0})
                            }} key={'admin-users-region-ceckable-card-'+0} />
                        {
                            regions.map((region)=>{
                                return (
                                    <CheckableCard title={region?.name} isActive={form?.region == region?.id} onClick={()=>setForm({...form, region:region?.id})} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
            {
                form?.region ?
                <div className="col-md-12">
                    <InputWrapper error={errors?.village} id={'admin-users-form-village'} label={"Etrap"} key={'admin-users-form-village-wrapper'}>
                        <div className="d-flex align-items-baseline justify-content-center">
                            <CheckableCard title={'Welaýat derejesi'} isActive={form?.village == 0} onClick={()=>{
                                setForm({...form, village:0})
                            }} key={'admin-users-village-ceckable-card-'+0} />
                            {
                                villages.map((village)=>{
                                    if(village?.region == form?.region){
                                        return (
                                            <CheckableCard title={village?.name} isActive={form?.village == village?.id} onClick={()=>{
                                                setForm({...form, village:village?.id}) 
                                            }} key={'admin-users-village-ceckable-card-'+village?.id} />
                                        )
                                    }
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
                :
                null
            }
            <div className="col-md-12">
                <InputWrapper error={errors?.office}  id={'personified-record-office'} label={"Edara"}>
                    <div className="d-flex justify-content-center flex-wrap">
                        {
                            officesArr.map((office)=>{
                                return (
                                    <CheckableCard title={office?.name} isActive={form?.office == office?.id} onClick={()=>setForm({...form, office:office?.id})} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
        </div>
    )
}