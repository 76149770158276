import { useState } from "react";
import AdminWrapper from "../Wrapper";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/page-title/page-title";


export default function AdminPersonified(){
    const [rentgenOptions, setRentgenOptions] = useState({
        options:{
            chart:{
                id:'apexchart-example-rentgen'
            },
            labels:['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust'],
            fill:{
                colors:['rgb(255, 169, 169)']
            }
        },
        xaxis:{
            type:'category',
            categories:['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust']
        },
        series:[
            {
                name:'Raýatlaýyn ýazgylar',
                data:[35, 42, 29, 53, 38, 27, 32, 25]
            }
        ],
    });
    return (
        <AdminWrapper>
            <PageTitle title={'Raýatlaýyn ýazgylar'} endComponent={
                <div className="d-flex align-items-center border rounded-2 border-1 p-2 text-small">
                    <i className="bi bi-calendar2-week me-2"></i>
                    01/01/23 - 04/10/23
                </div>
            }/>
            <div className="z-index-5 card border-0">
                <Chart options={rentgenOptions.options} series={rentgenOptions.series} chartOptions={rentgenOptions.chartOptions} type="bar" height={250}/>
            </div>

            <div className="card p-2 rounded-2">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center cursor-pointer">
                        <Link to={'/personified/create'} className="btn btn-primary btn-sm px-3">
                            <i className="bi bi-plus-lg me-2"></i>
                            Ýazgy goşmak
                        </Link>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn bg-accent-secondary btn-sm me-2">
                            <i className="bi bi-sliders"></i>
                        </button>
                        <select name="" id="" className="form-select form-select-sm">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>

                <table class="table table-striped text-small mb-2">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th>F.A.Aa</th>
                            <th>Jynsy</th>
                            <th>Doglan senesi</th>
                            <th>Ýazgy ýagdaýy</th>
                            <th>Habar beren edara</th>
                            <th>Sene</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Bakyýew A.G</td>
                            <td>
                                <span className="badge bg-accent-primary text-primary">
                                    Erkek
                                </span>
                            </td>
                            <td>12/02/1992</td>
                            <td>
                                <span className="badge bg-accent-danger text-danger">
                                Çak edilýän
                                </span>
                            </td>
                            <td>Lebap SES</td>
                            <td>04.10.2023 18:50</td>
                            <td>
                                <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-eye"></i>
                                </span>
                                <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-pen"></i>
                                </span>
                                <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                    <i className="bi bi-trash2"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="summary">
                        1-20 / 120
                    </div>
                    <ul class="pagination mb-0">
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-left"></i></span>
                        </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-right"></i></span>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
        </AdminWrapper>
    )
}