import InputBlock from "../../../../components/input-block/input-block";
export default function Form({form, setForm, errors, saveFunction}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-regions-form-name'} label={'Ady'} 
                value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                icon={'bi bi-keyboard'} />

            <InputBlock error={errors?.short_name} id={'admin-regions-form-shortname'} label={'Gysga ady'} 
                value={form?.short_name} setValue={(e)=>setForm({...form, short_name:e})}
                icon={'bi bi-keyboard'} />
        </form>
    )
}