import { Link, useLocation, useNavigate } from "react-router-dom";
import Configurations from "../../../../config/configurations";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

export default function AdminSidebar(){
    const navigate = useNavigate();
    const items = [
        {image:"grid.png", title:'Baş sahypa', link:'/'},
        {image:"baby.png", title:'Dogulyş', link:'/baby-registration', isActive:true},
        {image:"diagnose1.png", title:'Ýogalyş', link:'/die-registration', isActive:true},
        {image:"registration.png", title:'Bellige alma', link:'/person-registration', isActive:true},
        // {image:"personal_identifcator.png", title:'Şahsy belgi', link:'/person-identificator', isActive:true},
        {image:"marriage.png", title:'Nika baglaşmak', link:'/marriage', isActive:true},
        {image:"divorce.png", title:'Nika bozmak', link:'/marriage-broke', isActive:true},
        {image:"baby-care.png", title:'Hossarlyga almak', link:'/baby-care', isActive:true},
        {image:"change-name.png", title:'At üýtgetmek', link:'/change-name', isActive:true},
        {image:"paternity.png", title:'Atalygy tassyklamak', link:'/commit-parent', isActive:true},
        // {image:"documents.png", title:'Žurnallar', link:'/journals', isActive:true},
        // {image:"reports.png", title:'Hasabatlar', link:'/reports'},
        // {image:"bi bi-receipt", title:'Kepilnamalar', link:'/certificates'},
        // {image:"erdu.png", title:'ERDU', link:'/erdu'},
        {image:"library.png", title:'Kitaphana', link:'/library'},
        {image:"chat.png", title:'Çat', link:'/messenger'}
    ];
    const location = useLocation();
    return (
        <aside className="sidebar">
            <div className="mb-auto p-2">
                <ul className="sidebar-navs flex-column">
                    {
                        items.map((item)=>{
                            return (
                                <Link to={item.link} className={`nav-item ${item?.link === location?.pathname && 'active'}`}>
                                    <img src={`/img/${item?.image}`} alt="" className="sidebar-item-img p-1" height={35} />
                                    {item.title}
                                </Link>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="p-2 bg-dark text-light">
                <ul className="sidebar-navs flex-column mb-0">
                    <div className="nav-item text-light mb-2" onClick={()=>{
                        if(window.confirm("Siz hakykatdan hem ulgamdan çykmakçymy?")){
                            localStorage.removeItem('expTime')
                            navigate('/login')
                        }
                    }}>
                        <i className={"bi bi-box-arrow-right"}></i>
                        Ulgamdan çykmak
                    </div>
                    <div className="nav-item text-light">
                        <i className={"bi bi-info-circle"}></i>
                        Wersiýa {Configurations.VERSION}
                    </div>
                </ul>
            </div>
        </aside>
    )
}