import { appendNotification } from "../components/notification";

export const HOSPITAL_CODE = "AG-ON";
export function toggleSearchbar(){
    document.querySelector(".search-bar").classList.toggle("search-bar-show");
}

export function toggleRightbar(){
  document.querySelector(".rightbar").classList.toggle("active");
}

function select(el, all = false) {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

export function toggleSidebar(){
  select('body').classList.toggle('sidebar-active');
}

export function toggleFastLinks(){
  select('li.fast-links>ul').classList.toggle('show');
}

export function toDate(timestamp){
  let date = new Date(timestamp);
  return date.getFullYear()+"-"+getMonth(date)+"-"+getDay(date);
}

export function getDay(date){
  let day = date.getUTCDate();
  if(day<10){
    return "0"+day;
  }else{
    return day;
  }
}

export function getMonth(date){
  let month = date.getUTCMonth()+1;
  if(month<10){
    return "0"+month;
  }else{
    return month;
  }
}

export function toTimestamp(date){
  let arr = date.split("-");
  let newDate = new Date(arr[0], arr[1]-1, arr[2]);
  return newDate.getTime();
}


export function getStatuses(){
  const statuses = {
    0 : "Aktiw däl",
    10 : "Aktiw",
    90 : "Bloklanan",
    99 :"Ýok edilen" 
  };
  return statuses;
}

export function getStatusesArr(){
  const statuses = [
    {value: 0, txt : "Aktiw däl"},
    {value: 10, txt : "Aktiw"},
    {value: 90, txt : "Bloklanan"},
    {value: 99, txt : "Ýok edilen"}
  ];
  return statuses;
}

export const userStatus = 0;
export const adminStatus = 10;

export function getRoles(){
  return {
    0 : "User",
    10 : "Admin"
  };
}

export function getRolesArr(){
  return [
    {value:0, txt:"User"},
    {value:10, txt : "Admin"}
  ];
}

export function getMessageTypes(){
  return {
    0 : "Autorizasiýa",
    10 : "Paroly dikelt",
    20 : "Sargydy tassykla"
  };
}

export function getMessageTypesArr(){
  return [
    {value:0, txt:"Autorizasiýa"},
    {value:10, txt:"Paroly dikelt"},
    {value:20, txt:"Sargydy tassykla"}
  ];
}


export function getMessageStatuses(){
  return {
    0 : "Nobata duran",
    10 : "Iberilen",
    20 : "Ret edilen"
  };
}

export function getMessageStatusesArr(){
  return [
    {value:0, txt:"Nobata duran"},
    {value:10, txt:"Iberilen"},
    {value:20, txt:"Ret edilen"}
  ];
}

export function setSizes(){
  let main = document.querySelector("main");
  let header = document.querySelector("header");
  main.setAttribute("style", "height:"+(window.innerHeight-header.clientHeight)+"px; margin-top:"+header.clientHeight+"px;")
}

export const folders = {
  brand:"brand_images/",
  shop:"shop_images/",
  banner:"banner_images/",
  user:"user_images/",
  product:"product_images/",
  category:"category_images/",
  type:"type_images/",
  marketplace:"marketplace_images/",
  country:"country_images/",
  song:"song_images/"
}


export const basePath = '';

export function getPrice(product){
  return ((product?.discount ? (product?.selling_price-product?.selling_price*product?.discount/100) : (product?.selling_price))*1).toFixed(2);
}

export function getString(str){
  if(str !== null && str !== undefined){
    return(str)
  }else{
    return "";
  }
} 

export function firstLetterUpperCase(str){
  if((typeof str) == 'string'){
    str = `${str.substr(0,1).toLocaleUpperCase()}${str.substr(1)}`
  }
  return str;
}

export function toUppercase(str){
  if((typeof str) == 'string'){
    str = str?.toLocaleUpperCase();
  }
  return str;
}

export function reformatDate(date){
  if(date && date != null){
    const dateArr = date.split("-");
    return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
  }else{
    return "";
  }
}

export function toggleTreeItem(e){
  e?.target?.parentElement?.classList?.toggle('expanded');
}

export function addZero(number){
  if(number < 10){
    return "0"+number;
  }else{
    return number;
  }
}

export function getAge(date){
  if(date instanceof Date){
    const newDate = new Date();
    let age = newDate.getFullYear()-date?.getFullYear();
    if(!((newDate.getMonth() > date?.getMonth()) || (newDate.getMonth() === date?.getMonth() && newDate.getDay()>date.getDay() && newDate.getDay() !== date.getDay()))){
      age -=1
    }
    return age;
  }
  return false;
}

export function formatDate(date){
  if(date instanceof Date){
    let yyyy = date.getFullYear();
    let mm = date.getMonth()+1;
    let dd = date.getDate();
    if(dd < 10){
      dd = '0' + dd;
    }
    if(mm < 10){
      mm = '0' + mm;
    }
    return yyyy+'-'+mm+'-'+dd;
  }
  return false;
}

export function closeModal(){
  document.querySelector('.btn-close').click();
}

export function checkRequire(value){
  return value && ((value+"").trim() ? true : false);
}

export function checkPhone(phone){
  phone *=1;
  return(!isNaN(phone) && phone>=61000000 && phone <= 65999999);
}

export function checkOnlyLetters(value){
  return (/^[A-Za-zÝýÜüÄäŞşÖöŇňŽžÇç]+$/).test(value);
}

export function checkOnlyDigit(value){
  return (/^[0-9]+$/).test(value);
}

export const gendersArr = [
  {title:"Erkek", value:'M', img:'boy.png'},
  {title:"Aýal", value:'F', img:'girl.png'},
];

export const genders = {
  M:'Erkek',
  F:'Aýal',
  U:'Näbelli'
}

export const epidsArr = [
  {title:"Holera", value:10},
  {title:"Inçekesel", value:20},
  {title:"Gepatit", value:30},
  {title:"Doňuz gripi", value:40},
  {title:"Covid-19", value:50},
];

export const opsArr = [
  {title:"0-4", value:10},
  {title:"5-14", value:10},
  {title:"15-29", value:10},
  {title:"30-44", value:10},
  {title:"45+", value:10},
];

export const libItems = [
  {title:"Gollanmalar", value:10},
  {title:"Kitaplar", value:20},
  {title:"Görkezmeler", value:30},
  {title:"Buýruklar", value:40}
];

export function handleErrors(e, errors, setErrors, logout){
  console.error(e);
  if(e?.response?.status == 403){
      appendNotification('Siziň rugsatlary almaga ýeterlik priwelegiýaňyz ýok!', 'danger')
  }else if(e?.response?.status == 401){
    console.log(logout);
    logout()
  }else if(e?.response?.status == 400){
      appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
      setErrors(e?.response?.data?.errors)
  }else if(e?.response?.status == 500){
      appendNotification('Serwerde näsazlyk ýüze çykdy!', 'danger')
  }else{
      appendNotification('Näsazlyk ýüze çykdy!', 'danger')
  }
}


export const officeTypes = [
  {title:'Etrap', value:0},
  {title:'Welaýat', value:10},
  {title:'Döwlet', value:20},
];

export const officeTypesObj ={
  0:'Etrap',
  10:'Welaýat',
  20:'Döwlet',
};

export const epidStatuses = [
  {title:'Aktiw', value:0},
  {title:'Arhiwlenen', value:99},
];

export const optionTypes = [
  {title:'Ýaş topary', value:10},
  {title:'Barlag çäre', value:20},
  {title:'Geçirilen çäre', value:30},
];

export const epidStatusesObj = {
  0:'Aktiw',
  99:'Arhiwlenen'
};

export const professions = {
  0:'Başga',
  10:'Talyp',
  20:'Maldar',
  30:'Lukman',
  40:'Laborator işgär',
  50:'Transport işgär',
  60:'Näbelli'
};

export const passportSeries = ['I-AG', 'I-AH', 'I-MR', 'I-BN', 'I-LB', 'I-DZ', 'II-AG', 'II-AH', 'II-MR', 'II-BN', 'II-LB', 'II-DZ'];

export const personifiedRecordTypes = {
  0:'Näbelli',
  10:'Çak edilýän', 
  20:'Tassyklanan'
};

export const common_options = {
  10:'Ysgynsyzlyk',
  20:'Beden gyzgynlygy', 
  30:'Huş peselme',
  40:'Derleme'
};

export const cardio_options = {
  10:'Ysgynsyzlyk',
  20:'Ýürek agyry', 
  30:'Baş aýlanma',
  40:'Kelle agyry'
};

export const respirator_options = {
  10:'Dem gysma',
  20:'Üsgülewük', 
  30:'Ganly üsgülewük',
  40:'Howa ýetmezçiligi',
  50:'Hyžžyldy',
  60:'Döş kapasasynyň içinde agyry',
};

export const peptic_options = {
  10:'Iç geçme',
  20:'Iç agyry', 
  30:'Ganly uly täret',
  40:'Ýürek bulanma',
  50:'Gusma',
  60:'Işdäsizlik'
};

export const personRegisterActions = {
  10:'Hassa öýüne iberildi',
  20:'Hassahana ýatyryldy',
  30:'Karantine alyndy',
  40:'Barlaga iberildi',
  50:'Hassa ýogaldy',
};