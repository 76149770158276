export default function Sort({filter, setFilter, text, targetAttribute}){
    let className = "sort-link cursor-pointer";
    if(filter.sort == targetAttribute){
        className+=" asc";
    }
    if(filter.sort == "-"+targetAttribute){
        className += " desc";
    }
    return (
        <span className={className} onClick={(e) => {
            if(filter.sort == targetAttribute){
                setFilter({...filter, sort: ("-"+targetAttribute)});
            }else{
                setFilter({...filter, sort: targetAttribute});
            }
        }}>{text}</span>
    );
}