import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { epidStatuses } from "../../../../utils/main";
export default function Form({form, setForm, errors, setErrors, saveFunction}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-epids-form-name'} label={'Ady'} 
                value={form?.name} setValue={(e)=>{
                    setErrors({...errors, name:''})
                    setForm({...form, name:e})
                }}
                icon={'bi bi-keyboard'} />
            <InputBlock error={errors?.code} id={'admin-epids-form-code'} label={'Kody'} 
                value={form?.code} setValue={(e)=>{
                    setErrors({...errors, code:''})
                    setForm({...form, code:e})
                }}
                icon={'bi bi-123'} />
            <InputWrapper error={errors?.status} id={'admin-epids-form-status'} label={'Statusy'} key={'admin-epids-form-status-wrapper'}>
                <div className="d-flex flex-wrap justify-content-center">
                    {
                        epidStatuses.map((status)=>{
                            return (
                                <CheckableCard title={status.title} isActive={form?.status == status.value} 
                                    onClick={()=>{
                                        setErrors({...errors, status:''})
                                        setForm({...form, status:status.value})
                                    }} key={'admin-epids-item-'+status.value}/>
                            )
                        })
                    }
                </div>
            </InputWrapper>
        </form>
    )
}