import axios from 'axios';
import Configurations from '../../config/configurations';
const instance = axios.create({
    baseURL: Configurations.getServer(Configurations.MODE),
    timeout: 60000,
    headers: {
        "Accept": "application/json"
    }

});

export default class AxiosHelper {
    //#region Auth
    static async login(form = {}) {
        const promise = await instance.post("/auth/login", form);
        return promise;
    }

    static async confirm(form){
        const promise = await instance.post("/auth/confirm-number", form);
        return promise;
    }
    //#endregion

    //#region Admin

        //#region Statistic records

        static async getStatisticRecords(filter = {}, token){
            const promise = await instance.get("/admin/statistic-records",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getStatisticRecord(id, token){
            const promise = await instance.get("/admin/statistic-records/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createStatisticRecord(form, token){
            const promise = await instance.post("/admin/statistic-records", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateStatisticRecord(id, form, token){
            const promise = await instance.put("/admin/statistic-records/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteStatisticRecord(id, token){
            const promise = await instance.delete("/admin/statistic-records/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion


        //#region Users

        static async getUsers(filter = {}, token){
            const promise = await instance.get("/admin/user",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getUser(id, token){
            const promise = await instance.get("/admin/user/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createUser(form, token){
            const promise = await instance.post("/admin/user", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async changeUserPassword(id, form, token){
            const promise = await instance.put(`/admin/user/${id}/password`, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateUser(id, form, token){
            const promise = await instance.put("/admin/user/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteUser(id, token){
            const promise = await instance.delete("/admin/user/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        // #region Roles
        static async getRoles(filter = {}, token){
            const promise = await instance.get("/admin/roles",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getRole(id, token){
            const promise = await instance.get("/admin/roles/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getPermissions(token){
            const promise = await instance.get("/admin/roles/permissions",{
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createRole(form, token){
            const promise = await instance.post("/admin/roles", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateRole(id, form, token){
            const promise = await instance.put("/admin/roles/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteRole(id, token){
            const promise = await instance.delete("/admin/roles/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        // #region Regions
        static async getRegions(filter = {}, token){
            const promise = await instance.get("/admin/region",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getRegion(id, token){
            const promise = await instance.get("/admin/region/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createRegion(form, token){
            const promise = await instance.post("/admin/region", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateRegion(id, form, token){
            const promise = await instance.put("/admin/region/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteRegion(id, token){
            const promise = await instance.delete("/admin/region/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        // #region Epids
        static async getEpids(filter = {}, token){
            const promise = await instance.get("/admin/epids",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getEpid(id, token){
            const promise = await instance.get("/admin/epids/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createEpid(form, token){
            const promise = await instance.post("/admin/epids", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateEpid(id, form, token){
            const promise = await instance.put("/admin/epids/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteEpid(id, token){
            const promise = await instance.delete("/admin/epids/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion
        
        // #region Epid options
        static async getEpidOptions(filter = {}, token){
            const promise = await instance.get("/admin/epid-options",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getEpidOption(id, token){
            const promise = await instance.get("/admin/epid-options/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createEpidOption(form, token){
            const promise = await instance.post("/admin/epid-options", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateEpidOption(id, form, token){
            const promise = await instance.put("/admin/epid-options/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteEpidOption(id, token){
            const promise = await instance.delete("/admin/epid-options/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion
        
        // #region Diagnoses
        static async getDiagnoses(filter = {}, token){
            const promise = await instance.get("/admin/diagnoses",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getDiagnose(id, token){
            const promise = await instance.get("/admin/diagnoses/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createDiagnose(form, token){
            const promise = await instance.post("/admin/diagnoses", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateDiagnose(id, form, token){
            const promise = await instance.put("/admin/diagnoses/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteDiagnose(id, token){
            const promise = await instance.delete("/admin/diagnoses/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion


        // #region Offices
        static async getOffices(filter = {}, token){
            const promise = await instance.get("/admin/departments",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getOffice(id, token){
            const promise = await instance.get("/admin/departments/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createOffice(form, token){
            const promise = await instance.post("/admin/departments", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateOffice(id, form, token){
            const promise = await instance.put("/admin/departments/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteOffice(id, token){
            const promise = await instance.delete("/admin/departments/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        
        // #region Villages
        static async getVillages(filter = {}, token){
            const promise = await instance.get("/admin/village",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getVillage(id, token){
            const promise = await instance.get("/admin/village/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createVillage(form, token){
            const promise = await instance.post("/admin/village", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateVillage(id, form, token){
            const promise = await instance.put("/admin/village/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteVillage(id, token){
            const promise = await instance.delete("/admin/village/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion


        //#region Messages
        static async resendMessage(number){
            const promise = await instance.post("/message/resend",{phone_number:number});
            return promise;
        }

        static async getMessages(filter = {}, token){
            const promise = await instance.get("/admin/message",{
                params:{
                    sort: filter.sort,
                    filter: filter,
                    limit: filter.limit,
                    page: filter.page
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        
        static async getMessagesSummary(token){
            const promise = await instance.get("/admin/message/summary",{
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getMessage(id, token){
            const promise = await instance.get("/admin/message/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createMessage(form, token){
            const promise = await instance.post("/admin/message", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateMessage(id, form, token){
            const promise = await instance.put("/admin/message/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteMessage(id, token){
            const promise = await instance.delete("/admin/message/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async multiDeleteMessage(form, token){
            const promise = await instance.post("/admin/message/delete", form,
            {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        //#region Countries
        static async getCountries(filter = {}, token){
            const promise = await instance.get("/admin/country",{
                params:{
                    sort: filter.sort,
                    filter: filter,
                    limit: filter.limit,
                    page: filter.page
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getCountry(id, token){
            const promise = await instance.get("/admin/country/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createCountry(form, token){
            const promise = await instance.post("/admin/country", form, {
                headers:{
                    "Content-type" : "multipart/form-data",
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updateCountry(id, form, token){
            const promise = await instance.put("/admin/country/"+id, form, {
                headers:{
                    "Content-type" : "multipart/form-data",
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deleteCountry(id, token){
            const promise = await instance.delete("/admin/country/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async multiDeleteCountry(form, token){
            const promise = await instance.post("/admin/country/delete", form,
            {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

        // #region Patients
        static async getPatients(filter = {}, token){
            const promise = await instance.get("/admin/patients",{
                params:{
                    ...filter
                },
                headers :{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async getPatient(id, token){
            const promise = await instance.get("/admin/patients/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async createPatient(form, token){
            const promise = await instance.post("/admin/patients", form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async updatePatient(id, form, token){
            const promise = await instance.put("/admin/patients/"+id, form, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }

        static async deletePatient(id, token){
            const promise = await instance.delete("/admin/patients/"+id, {
                headers:{
                    authorization:`Bearer ${token}`
                }
            });
            return promise;
        }
        //#endregion

    //#endregion Admin 

    //#region  Client
    
    //#endregion Client
}