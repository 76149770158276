import TagsInput from "react-tagsinput";
import CheckableCard from "../../../../components/checkable-card/checkable-card";
import Modal from "../../../../components/modal/modal";
import { libItems } from "../../../../utils/main";
import { useState } from "react";
import FileDropzone from "../../../../components/dropzone/dropzone";

export default function AddNewBook(){
    const modalId = 'addBookModal';
    const [tags, setTags] = useState([]);
    return (
        <>
        <button className="btn btn-primary btn-sm px-3 text-nowrap" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
            <i className="bi bi-plus-lg me-2"></i>
            Täze goşmak
        </button>
        <Modal id={modalId} isFooter={false} isHeader={false} modalSize="modal-lg">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-5">Täze gollanma goşmak</span>
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm bg-accent-secondary text-secondary btn-collapsable me-2" data-bs-dismiss="modal">
                        <i className="bi bi-x me-2"></i>
                        <span className="collapse-text">Ret etmek</span>
                    </button>
                    <button className="btn btn-sm bg-accent-primary text-primary btn-collapsable me-2">
                        <i className="bi bi-check2-square me-2"></i>
                        <span className="collapse-text">Ýatda sakla</span>
                    </button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="d-grid">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="" className="form-label mb-2">Gollanma ady</label>
                        <div className="position-relative">
                            <input type="text" className="form-control form-control-sm" />
                            <i className="bi bi-journal-bookmark position-absolute end-0 me-2 top-50 translate-middle-y"></i>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="" className="form-label mb-2">Gollanma awtory</label>
                        <div className="position-relative">
                            <input type="text" className="form-control form-control-sm" />
                            <i className="bi bi-person position-absolute end-0 me-2 top-50 translate-middle-y"></i>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="" className="form-label mb-2">Tegler</label>
                        <TagsInput value={tags} onChange={setTags} maxTags={5} inputProps={{placeholder:'Teg goşmak'}}/>
                    </div>
                    <div className="col-md-12 mb-2">
                        <label htmlFor="" className="form-label mb-2">Gollanma kategoriýasy</label>
                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                            {
                                libItems.map((lib)=>{
                                    return(
                                        <CheckableCard title={lib.title} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="" className="form-label mb-2">
                            Gollanma faýly 
                            
                            <span className="fw-lighter fst-italic ms-2">
                                (.pdf)
                            </span>
                        </label>
                        <FileDropzone accept={{
                            'application/pdf':['.pdf']
                        }} maxFiles={1}/>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}