import { checkOnlyDigit, checkOnlyLetters, checkPhone, checkRequire, handleErrors } from "../../../utils/main";
import PageTitle from "../../../components/page-title/page-title";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../components/notification";
import { useNavigate } from "react-router-dom";
import FormStep from "../../../components/form-step/step";
import MainElement from "./form-elements/main";
import OfficeElement from "./form-elements/office";
export default function Form({form, setForm, errors, setErrors, isUpdate}){
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [epids, setEpids] = useState([]);
    const [opts, setOpts] = useState([]);
    const [offices, setOffices] = useState([]);
    const {isReady, token, logout} = useContext(AuthContext);
    const [officesArr, setOfficesArr] = useState([]);
    const [optsArr, setOptsArr] = useState([]);
    useEffect(()=>{
        let arr = [];
        Object.keys(offices).map((key)=>{
            let office = offices[key];
            if(form?.region == office?.region){
                if(form?.village && form?.village == office?.village){
                    arr.push(office);
                }else if(form?.village == 0 && office?.village == null){
                    arr.push(office);
                }
            }else if(form.region == 0 && office.region == null){
                arr.push(office);
            }
        })
        setOfficesArr(arr);
    }, [offices, form?.region, form?.village])
    const navigate = useNavigate();
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e))
            AxiosHelper.getOffices({limit:10000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((office)=>{
                        obj[office?.id] = office;
                    })
                    setOffices(obj);
                }
            }).catch((e)=>handleErrors(e))
            AxiosHelper.getEpids({limit:1000}, token).then((result)=>{
                if(result.status == 200){ 
                    setEpids(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))

            AxiosHelper.getEpidOptions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((opt)=>{
                        obj[opt?.id] = opt;
                    })
                    setOpts(obj)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
        }
    }, [isReady]) 
    useEffect(()=>{
        let _arr = {};
        let optsObj = {};
        optsArr.map((opt)=>{
            optsObj[opt?.id] = 0;
        })
        officesArr.map((off)=>{
            _arr[off?.id]=optsObj;
        })
        setForm({...form, offices:_arr});
        console.log(form?.offices);
    }, [optsArr, officesArr])
    useEffect(()=>{
        let arr = [];
        Object.keys(opts).map((optKey)=>{
            let opt = opts[optKey];
            if(opt.epids?.includes(form?.epid)){
                arr.push(opt);
            }
        })
        setOptsArr(arr);
    }, [form?.epid])
    function save(){
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateStatisticRecord(form?.id, form, token) : AxiosHelper.createStatisticRecord(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Statistiki ýazgy üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        navigate('/statistic')
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};

        if(!checkRequire(form?.date)){
           err.date='Hasabat senesini giriziň'
        }else{
            err.date = null;
        }

        if(!checkRequire(form?.epid)){
           err.epid='Keselçilik görnüşini saýlaň'
        }else{
            err.epid = null;
        }
        setErrors({...errors, ...err})
        return err.date || err.epid;
    }
    return (
        <>
            <PageTitle title={!isUpdate ? "Täze statistiki ýazgy goşmak" : 'Statistiki ýazgyny üýtgetmek'} endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm btn-light px-2 me-2" onClick={()=>navigate('/statistic')}>
                        <i className="bi bi-x-lg me-2"></i>
                        Ret etmek
                    </button>
                    <button className="btn btn-sm bg-accent-primary text-primary px-2" onClick={save}>
                        <i className="bi bi-check2-square me-2"></i>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            
            <div className="steps">
                <FormStep id={'form-user-login-data'} stepName={"Ädim 1"} 
                    title={"Hasabat maglumatlar"} isActive={true} key={'form-user-login-data-step'}
                    content={
                        <MainElement errors={errors} form={form} setForm={setForm} key={'form-user-main-element'}  
                            setErrors={setErrors} offices={offices} regions={regions} villages={villages} epids={epids}/>
                    }/>
                {
                    Object.keys(form.offices).map((officeKey)=>{
                        let office = offices[officeKey];
                        return (
                            <FormStep id={'form-statistic-data-office-element-'+office?.id} stepName={office?.name} 
                                title={office?.name} isActive={false} key={'form-statistic-data-office-element-'+office?.id}
                                content={
                                    <OfficeElement errors={errors} setErrors={setErrors} 
                                        form={form} setForm={setForm} 
                                        offices={offices} regions={regions} 
                                        villages={villages} key={'form-statistic-data-office-element-'+office?.id} 
                                        opts={opts} office={office} officeKey={officeKey}
                                        />
                                }/>
                        )
                    })
                }
            </div>
        </>
    )
}