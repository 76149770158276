import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { epidStatuses, optionTypes } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction, epids}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <div className="row">
                <div className="col-md-6">
                    <InputBlock error={errors?.name} id={'admin-epid-options-form-name'} label={'Ady'} 
                        value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                        icon={'bi bi-keyboard'} />
                </div>
                <div className="col-md-6">
                    <InputBlock error={errors?.start} id={'admin-epid-options-form-start'} label={'Başlangyç ýaş'} 
                        value={form?.start} setValue={(e)=>setForm({...form, start:e})}
                        icon={'bi bi-123'} />
                </div>
                <div className="col-md-6">
                    <InputWrapper error={errors?.status} id={'admin-epids-options-form-status'} label={'Statusy'} key={'admin-epids-options-form-status-wrapper'}>
                        <div className="d-flex flex-wrap justify-content-center">
                            {
                                epidStatuses.map((status)=>{
                                    return (
                                        <CheckableCard title={status.title} isActive={form?.status == status.value} 
                                            onClick={()=>setForm({...form, status:status.value})} key={'admin-epids-option-item-'+status.value}/>
                                    )
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
                <div className="col-md-6">
                    <InputWrapper error={errors?.type} id={'admin-epid-option-form-type'} label={'Görnüşi'} key={'admin-epid-option-form-type-wrapper'}>
                        <div className="d-flex flex-wrap justify-content-center">
                            {
                                optionTypes.map((type)=>{
                                    return (
                                        <CheckableCard title={type.title} isActive={form?.type == type.value} 
                                            onClick={()=>setForm({...form, type:type.value})} key={'admin-epid-option-type-item-'+type.value}/>
                                    )
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
                <div className="col-md-12">
                    <InputWrapper  error={errors?.epids} id={'admin-option-form-epids'} label={'Degişli keselleri'}>
                        <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                            {
                                Array.from(epids).map((epid)=>{
                                    return (
                                        <CheckableCard key={`admin-option-epids-card-${epid.id}`} title={epid?.name} 
                                            isActive={Array.from(form?.epids).includes(epid.id)} 
                                            onClick={()=>{
                                                let arr = [...form.epids];
                                                if(arr.includes(epid.id)){
                                                    arr = arr.filter((_)=>_!=epid.id)
                                                }else{
                                                    arr.push(epid.id)
                                                }
                                                setForm({...form, epids:arr});
                                            }}/>
                                    )
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
            </div>
        </form>
    )
}