import { useEffect } from "react"

export default function AutoSearchInput({time = 2000, runFunction=()=>{}, text, setText, id='autoSearch', placeholder="Gözleg..."}){
    let typingTimer;
    useEffect(()=>{
        if(text.trim() && text.trim() != null){
            typingTimer = setTimeout(runFunction, time);
        }
        return ()=>clearTimeout(typingTimer);
    }, [text])
    useEffect(()=>{
        runFunction()
    },[])
    return (
        <div className="position-relative w-100">
            <input type="text" className="form-control fillingFormInput" id={id} placeholder={placeholder} value={text} onChange={(e)=>setText(e.target.value)} onKeyDown={(e)=>{
                if(e.code == 'Enter'){
                    runFunction()
                }
            }}/>
            <i className="bi bi-search position-absolute end-0 top-50 translate-middle-y pe-2 text-primary fw-bold cursor-pointer" title="Gözlemek" onClick={()=>{
                clearTimeout(typingTimer)
                runFunction()
            }}></i>
        </div>
    )
}