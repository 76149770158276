import { checkOnlyLetters, checkPhone, checkRequire, handleErrors } from "../../../../utils/main";
import PageTitle from "../../../../components/page-title/page-title";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { useNavigate } from "react-router-dom";
import FormStep from "../../../../components/form-step/step";
import UserDataElement from "./form-elements/user-data";
import MainElement from "./form-elements/main";
import OfficeElement from "./form-elements/office";
export default function Form({form, setForm, errors, setErrors, isUpdate}){
    const [roles, setRoles] = useState({});
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [offices, setOffices] = useState([]);
    const {isReady, token, logout} = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRoles({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((role)=>{
                        obj[role?.id] = role;
                    })
                    setRoles(obj);
                }
            }).catch((e)=>{
                handleErrors(e, errors, setErrors, logout)
            })
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e))
            AxiosHelper.getOffices({limit:10000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((office)=>{
                        obj[office?.id] = office;
                    })
                    setOffices(obj);
                }
            }).catch((e)=>handleErrors(e))
        }
    }, [isReady]) 
    function save(){
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateUser(form?.id, form, token) : AxiosHelper.createUser(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Ulanyjy üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        navigate('/settings/users')
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Ulanyjynyň adyny giriziň'
        }else if(form?.name?.length > 100){
            err.name='Ulanyjynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Ulanyjynyň ady diňe harplardan ybarat bolmaly';
        }else{
            err.name = null;
        }

        if(!checkRequire(form?.surname)){
           err.surname='Ulanyjynyň familiýasyny giriziň'
        }else if(form?.surname?.length > 100){
            err.surname='Ulanyjynyň familiýasy 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.surname)){
            err.surname='Ulanyjynyň familiýasy diňe harplardan ybarat bolmaly';
        }else{
            err.surname = null;
        }

        if(form?.second_name?.length > 100){
            err.second_name='Ulanyjynyň atasynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(form?.second_name && !checkOnlyLetters(form?.second_name)){
            err.second_name='Ulanyjynyň atasynyň ady diňe harplardan ybarat bolmaly';
        }else{
            err.second_name = null;
        }

        if(!checkRequire(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini giriziň'
        }else if(!checkPhone(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini dogry giriziň';
        }else{
            err.phone_number = null;
        }

        if(!checkRequire(form?.phone_number)){
             err.phone_number='Ulanyjynyň telefon belgisini giriziň'
        }else if(!checkPhone(form?.phone_number)){
            err.phone_number='Ulanyjynyň telefon belgisini dogry giriziň';
        }else{
            err.phone_number = null;
        }

        if(!checkRequire(form?.name)){
            err.login='Ulanyjy adyny giriziň'
        }else if(form?.login?.length > 100){
            err.login='Ulanyjy ady 100 harpdan uzyn bolmaly däl'
        }else{
            err.login = null;
        }

        if(!isUpdate &&!checkRequire(form?.password)){
            err.password='Açar sözüni giriziň'
        }else if(!isUpdate && form?.password?.length > 100){
            err.password='Açar sözi 100 harpdan uzyn bolmaly däl'
        }else{
            err.password = null;
        }

        if(form?.status === null || form?.status === undefined){
            err.status='Ulanyjy statusyny saýlaň'
        }else{
            err.status = null;
        }

        if(form?.role === null || form?.role === undefined){
            err.role='Ulanyjy roluny saýlaň'
        }else{
            err.role = null;
        }

        if(!checkRequire(form?.department)){
            err.office='Ulanyjynyň degişli bolan edarasyny saýlaň'
        }else{
            err.office = null;
        }
        setErrors({...errors, ...err})
        return err.name || err.surname || err.second_name || err.phone_number || err.login || err.password || err.status || err.role || err.office;
    }
    return (
        <>
            <PageTitle title={!isUpdate ? "Täze ulanyjy goşmak" : 'Ulanyjyny üýtgetmek'} endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm btn-light px-2 me-2" onClick={()=>navigate('/settings/users')}>
                        <i className="bi bi-x-lg me-2"></i>
                        Ret etmek
                    </button>
                    <button className="btn btn-sm btn-primary px-2" onClick={save}>
                        <i className="bi bi-check2-square me-2"></i>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            
            <div className="steps">
                <FormStep id={'form-user-data'} stepName={"Ädim 1"} 
                    title={"Esasy maglumatlar"} isActive={true} key={'form-user-data-step'}
                    content={
                        <UserDataElement errors={errors} form={form} setForm={setForm} key={'form-user-user-data-element'} setErrors={setErrors} />
                    }/>
                <FormStep id={'form-user-login-data'} stepName={"Ädim 2"} 
                    title={"Giriş maglumatlary"} isActive={true} key={'form-user-login-data-step'}
                    content={
                        <MainElement errors={errors} form={form} setForm={setForm} roles={roles} key={'form-user-main-element'}  setErrors={setErrors}/>
                    }/>
                <FormStep id={'form-user-office-data'} stepName={"Ädim 3"} 
                    title={"Degişli edarasy"} isActive={true} key={'form-user-office-data-step'}
                    content={
                        <OfficeElement errors={errors} form={form} setForm={setForm} offices={offices} regions={regions} setErrors={setErrors} villages={villages} key={'form-user-office-element'} />
                    }/>
            </div>
        </>
    )
}