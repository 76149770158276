import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [user, setUser]  = useState(null);
    const [isReady, setReady]  = useState(false);

    const login = useCallback((token, user) => {
        setToken(token);
        setUser(user);
        localStorage.setItem("userData", JSON.stringify({
            token:token,
            user:user
        }))
    }, []);

    const logout = () => {
        setToken(null);
        setUser(null);
        localStorage.removeItem("userData");
    }

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("userData"))
        if(data && data.token){
            login(data.token, data.user); 
        }
        setReady(true);
    }, [login])

    return {login, logout, token, user, isReady}; 
}