import { Link } from "react-router-dom";
import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";

export default function MarriageBrokeRegistration(){
    return (
        <AdminWrapper>
            <PageTitle title={"Nika bozmagy hasaba almak"} />
            <div className="card p-2 rounded-2">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center cursor-pointer">
                        <button to={'/certificates'} className="btn btn-primary btn-sm px-3">
                            <i className="bi bi-plus-lg me-2"></i>
                            Nika bozmagy  almak
                        </button>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn bg-accent-secondary btn-sm me-2">
                            <i className="bi bi-sliders"></i>
                        </button>
                        <select name="" id="" className="form-select form-select-sm">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <table class="table table-striped text-small mb-2">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th>Öýlenýän</th>
                            <th>Durmuşa çykýan</th>
                            <th>Nika belgisi</th>
                            <th>Nika senesi</th>
                            <th>Nika ýagdaýy</th>
                            <th>Has. alnan wagty</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        [...Array(15)].map((i)=>{
                                return (
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Bakyýew A.G</td>
                                        <td>Bakyýewa M.T</td>
                                        <td>
                                            I-MR123456
                                        </td>
                                        <td>12/10/2023</td>
                                        <td>
                                            <span className="badge bg-accent-secondary text-secondary">
                                                Birilenji
                                            </span>
                                        </td>
                                        <td>04.10.2023 18:50</td>
                                        <td>
                                            <span className="p-1 bg-accent-warning text-warning rounded-2 px-2 cursor-pointer me-2">
                                                <i className="bi bi-eye"></i>
                                            </span>
                                            <span className="p-1 bg-accent-primary text-primary rounded-2 px-2 cursor-pointer me-2">
                                                <i className="bi bi-pen"></i>
                                            </span>
                                            <span className="p-1 bg-accent-danger text-danger rounded-2 px-2 cursor-pointer me-2">
                                                <i className="bi bi-trash2"></i>
                                            </span>
                                        </td>
                                    </tr>
                                )
                        })
                    }
                    </tbody>
                </table>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="summary">
                        1-20 / 120
                    </div>
                    <ul class="pagination mb-0">
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-left"></i></span>
                        </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-right"></i></span>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
        </AdminWrapper>
    )
}