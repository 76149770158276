import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { addZero, checkOnlyLetters, checkRequire, getStatuses, getString, handleErrors } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import { Link } from "react-router-dom";
import Filter from "./filter";

export default function AdminUsers(){
    const [data, setData] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [roles, setRoles] = useState({});
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({name:''});
    const [errors, setErrors] = useState({name:null});
    const [filter, setFilter] = useState({page:1, limit:10, statuses:[], roles:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-users-modal';
    const pageKey="admin-users-page";
    const pageTitle="Ulanyjylar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getPermissions(token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((perm)=>{
                        obj[perm?.id] = perm;
                    })
                    setPermissions(obj);
                }
            }).catch((e)=>{
                handleErrors(e, errors, setErrors, logout)
            })
            AxiosHelper.getRoles({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((role)=>{
                        obj[role?.id] = role;
                    })
                    setRoles(obj);
                }
            }).catch((e)=>{
                handleErrors(e, errors, setErrors, logout)
            })
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e))
        }
    }, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="F.A" filter={filter} setFilter={setFilter} targetAttribute='surname'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.surname)} ${getString(item?.name)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Ulanyjy ady" filter={filter} setFilter={setFilter} targetAttribute='login'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.login) ? getString(item?.login) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Tel. belgisi" filter={filter} setFilter={setFilter} targetAttribute='phone_number'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.phone_number) ? '+993 '+getString(item?.phone_number) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Statusy" filter={filter} setFilter={setFilter} targetAttribute='status'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(getStatuses()[item?.status])}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Roly" filter={filter} setFilter={setFilter} targetAttribute='role'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(roles[item?.role]?.name)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${addZero(date.getDate())}-${addZero(date.getMonth()+1)}-${date.getFullYear()}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu ulanyjyny ýok etmekçimi?")){
                                AxiosHelper.deleteUser(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Ulanyjy üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <Link className="btn btn-sm bg-accent-primary text-primary m-1" to={'/settings/users/update/'+item?.id}>
                            <i className="bi bi-pencil"></i>
                        </Link>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getUsers(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, errors, setErrors, logout)
        }).finally(()=>setIsLoading(false));
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            isFilterable={true} filterBlock={<Filter filter={filter} setFilter={setFilter} roles={roles}/>}
            startBlock={
                <Link to={'/settings/users/create'} className="btn btn-sm btn-primary px-3 text-nowrap">
                    <i className="bi bi-plus-lg me-2"></i>
                    Täze goşmak
                </Link>
            } />
    )
}