import InputBlock from "../input-block/input-block";
import Modal from "../modal/modal";
export default function ModalWithButton({modalId, title, closeRef, 
                                        onButtonClick = ()=>{}, buttonProps=null,
                                        modalPosition='modal-dialog-centered', modalSize='modal-lg', 
                                        isSaveEnable=false, saveFunction=()=>{}, 
                                        ...props}){
    return (
        <>
            <button className="btn btn-primary btn-sm px-3 text-nowrap" 
                data-bs-toggle="modal" data-bs-target={`#${modalId}`} 
                onClick={onButtonClick} {...buttonProps}>
                <i className="bi bi-plus-lg me-2"></i>
                Täze goşmak
            </button>
            <Modal id={modalId} isFooter={false} isHeader={false} modalPosition={modalPosition} modalSize={modalSize}>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="fs-5 fw-500 text-themed">
                        {title}
                    </span>
                    <div className="d-flex align-items-center">
                        <button className="btn bg-accent-secondary text-secondary btn-sm px-3" data-bs-dismiss="modal" ref={closeRef}>
                            <i className="bi bi-x-lg me-2"></i>
                            Ýapmak
                        </button>
                        {
                            isSaveEnable ?
                            <button className="btn bg-accent-primary text-primary btn-sm px-3 ms-2" onClick={saveFunction}>
                                <i className="bi bi-check2-square me-2"></i>
                                Ýatda sakla
                            </button>
                            : null
                        }
                    </div>
                </div>
                <hr className="my-2" />
                {props.children}
            </Modal>
        </>
    )
}