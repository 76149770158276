import Checkbox from "../../../../components/checkbox/checkbox"
import { epidStatuses } from "../../../../utils/main";

export default function Filter({filter, setFilter}){
    return (
        <div className="roles-filter d-grid px-2 text-small" style={{width:200+'px'}}>
        {
            epidStatuses.map((status)=>{
                return (
                    <Checkbox title={status?.title} id={'status-'+status?.value} wrapperOptions={{className:'mb-1 form-check'}}
                        isChecked={filter.statuses.includes(status?.value)} 
                        onChange={(e)=>{
                            let arr = [...filter.statuses];
                            if(e.target?.checked){
                                arr.push(status.value);
                                setFilter({...filter, statuses:arr})
                            }else{
                                arr = arr.filter((_)=>_ != status.value);
                                setFilter({...filter, statuses:arr})
                            }
                        }}/>
                )
            })
        }
        </div>
    )
}