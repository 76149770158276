import { useEffect } from "react"

export default function Modal({modalTitle, id, saveText="Ýatda sakla", closeText="Ret etmek", saveFunction, isFooter=true, isHeader=true, 
    modalPosition="modal-dialog-centered", modalSize="", ...props}){
    useEffect(()=>{
        return function(){
            document.querySelector('.modal-backdrop')?.remove()
        };
    }, [])
    return (
        <div class="modal fade" id={id} tabindex="-1" style={{display: "none"}} data-bs-backdrop="static" aria-hidden="true">
            <div class={`modal-dialog ${modalPosition} ${modalSize}`}>
                <div class="modal-content">
                    {
                        isHeader ?
                        <div class="modal-header">
                            <h5 class="modal-title">{modalTitle}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        : null
                    }
                    <div class="modal-body">
                        {
                            props.children
                        }
                    </div>
                    {
                        isFooter ?
                        <div class="modal-footer">
                            <button type="button" class="btn btn-link-secondary" data-bs-dismiss="modal">{closeText}</button>
                            <button type="button" class="btn btn-link-teal" onClick={saveFunction}>{saveText}</button>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}