import { Link, useLocation, useNavigate } from "react-router-dom";
import AdminLogo from "../admin/_components/header/_logo";
import Configurations from "../../config/configurations";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function AuthWrapper(props){
    const {isReady, isLogin} = useContext(AuthContext);
    const navigate = useNavigate();
    // useEffect(()=>{
    //     if(isReady && isLogin){
    //         navigate('/')
    //     }
    // }, [isReady, isLogin])
    const location = useLocation();
    useEffect(()=>{
        const expTime = localStorage.getItem('expTime');
        
        if(expTime && expTime*1>(new Date().getTime()) ){
            navigate('/')
        }
    }, [location.pathname])
    return (
        <div className="w-100 vh-100 auth-wrapper">
            <div className="col-md-6 col-lg-7 d-none d-md-block bg-auth h-100 position-relative">
                <div className="gray-overlay"></div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 form-container h-100">
                <div className="d-grid">
                    <Link to="/" className="admin-logo mb-3">
                        {/* <img src="/img/favicon.png" alt="ADMSES logo"/> */}
                        <span>{Configurations.APPNAME}</span>
                    </Link>
                    {
                        props.children
                    }
                </div>
            </div>
            <div className="position-absolute d-flex align-items-center w-100 justify-content-between top-0 start-0 p-3">
                <p className="border-start border-white border-5 ps-2 fw-500 text-white fs-5 d-none d-md-block" style={{maxWidth:400+'px'}}>
                    {Configurations.FULLAPPNAME}
                </p>
                <img src="/img/sgm-logo.png" alt="" height={75}/>
            </div>
            <div className="position-absolute bottom-0 start-0 p-3 w-100">
                <hr className="border-white mb-0"/>
                <div className="d-flex align-items-center w-100 justify-content-between ">
                    <p className="ps-2 text-white d-none d-md-block">
                        Copyright &copy; 2023. Ähli hukuklary goralan 
                    </p>
                    <span className="text-small fw-500 text-slatgray">
                        Powered by {Configurations.COMPANY_NAME} {Configurations.COMPANY_APPENDIX}
                    </span>
                </div>
            </div>
        </div>
    )
}