import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import { addZero, checkOnlyLetters, checkRequire, genders, gendersArr, getString, handleErrors, professions } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";

export default function AdminPatients(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({name:''});
    const [errors, setErrors] = useState({name:null});
    const [filter, setFilter] = useState({page:1, limit:10});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-patients-modal';
    const pageKey="admin-patients-page";
    const pageTitle="Raýatlar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="F.A.Aa" filter={filter} setFilter={setFilter} targetAttribute='surname'/>, content:(item)=>{
                return (
                    <span className="text-primary ms-2 fw-bold">
                        {`${getString(item?.surname)} ${getString(item?.name)} ${getString(item?.second_name)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Doglan güni" filter={filter} setFilter={setFilter} targetAttribute='birth_date'/>, content:(item)=>{
                const bdate = new Date(item?.birth_date);
                return (
                    <span className="text-dark ms-2 fw-500">
                        {`${addZero(bdate.getDate())}-${addZero(bdate.getMonth()+1)}-${bdate.getFullYear()}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Passport belgisi" filter={filter} setFilter={setFilter} targetAttribute='ps_number'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-primary text-primary ms-2 fw-bold">
                        {`${getString(item?.ps_serial_key)} ${getString(item?.ps_number)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Jynsy" filter={filter} setFilter={setFilter} targetAttribute='gender'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-danger text-danger ms-2 fw-bold">
                        {genders[item?.gender]}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Hünäri" filter={filter} setFilter={setFilter} targetAttribute='profession'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.profession ? professions[item.profession] : item?.profession_text}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDay())}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu welaýaty ýok etmekçimi?")){
                                AxiosHelper.deleteRegion(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Welaýat üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getPatients(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, errors, setErrors, logout)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updatePatient(form?.id, form, token) : AxiosHelper.createPatient(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Raýat üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
            err.name='Ulanyjynyň adyny giriziň'
        }else if(form?.name?.length > 100){
            err.name='Ulanyjynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Ulanyjynyň ady diňe harplardan ybarat bolmaly';
        }else{
            err.name = null;
        }
    
        if(!checkRequire(form?.surname)){
            err.surname='Ulanyjynyň familiýasyny giriziň'
        }else if(form?.surname?.length > 100){
            err.surname='Ulanyjynyň familiýasy 100 harpdan uzyn bolmaly däl'
        }else if(!checkOnlyLetters(form?.surname)){
            err.surname='Ulanyjynyň familiýasy diňe harplardan ybarat bolmaly';
        }else{
            err.surname = null;
        }
    
        if(form?.second_name?.length > 100){
            err.second_name='Ulanyjynyň atasynyň ady 100 harpdan uzyn bolmaly däl'
        }else if(form?.second_name && !checkOnlyLetters(form?.second_name)){
            err.second_name='Ulanyjynyň atasynyň ady diňe harplardan ybarat bolmaly';
        }else{
            err.second_name = null;
        }
    
        if(!checkRequire(form?.ps_serial_key)){
            err.ps_serial_key='Raýatyň passport seriýasyny saýlaň'
        }else{
            err.ps_serial_key = null;
        }
    
        if(!checkRequire(form?.ps_number)){
            err.ps_number='Raýatyň passport belgisini saýlaň'
        }else{
            err.ps_number = null;
        }
    
        if(!checkRequire(form?.gender) || !['M', 'F', 'U'].includes(form?.gender)){
            err.gender='Raýat jynsyny saýlaň'
        }else{
            err.gender = null;
        }
    
        if(!checkRequire(form?.birth_date)){
        err.birth_date='Raýatyň doglan günini giriziň'
        }else{
            err.birth_date = null;
        }

        setErrors({...errors, ...err})
        return err.name || err.surname || err.second_name || err.birth_date || err.gender || err.ps_number || err.ps_serial_key;
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle}
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Raýaty üýtgetmek' :"Täze raýat goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} modalSize="modal-xl"
                    onButtonClick={
                        ()=>{
                            setIsUpdate(false); 
                            setForm({name:'', surname:'', second_name:'', gender:'', birth_date:'', ps_serial_key:'', ps_number:''});
                            setErrors({})
                        }
                    }>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}