import CheckableCard from "../../../components/checkable-card/checkable-card"
import Checkbox from "../../../components/checkbox/checkbox"
import Modal from "../../../components/modal/modal"
import { epidsArr, gendersArr, opsArr } from "../../../utils/main"

export default function ReportsFilter(){
    const modalId = "reportsFilterModal"
    return(
        <>
         <button className="btn btn-light btn-sm" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
            <i className="bi bi-sliders"></i>
         </button>
         <Modal id={modalId} isHeader={false} isFooter={false} modalSize="modal-lg">
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-5">Hasabat filteri</span>
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm bg-accent-secondary text-secondary btn-collapsable me-2" data-bs-dismiss="modal">
                        <i className="bi bi-x me-2"></i>
                        <span className="collapse-text">Ret etmek</span>
                    </button>
                    <button className="btn btn-sm bg-accent-warning text-warning btn-collapsable me-2">
                        <i className="bi bi-arrow-repeat me-2"></i>
                        <span className="collapse-text">Arassala</span>
                    </button>
                    <button className="btn btn-sm bg-accent-primary text-primary btn-collapsable me-2">
                        <i className="bi bi-check2-square me-2"></i>
                        <span className="collapse-text">Ýatda sakla</span>
                    </button>
                </div>
            </div>
            <hr className="my-2" />
            <div className="row">
                <div className="col-md-6 mb-2">
                    <label htmlFor="" className="form-label mb-2">Welaýat</label>
                    <select name="" id="" className="form-select form-select-sm">
                        <option value="">...</option>
                        <option value="">Aşgabat</option>
                        <option value="">Ahal</option>
                        <option value="">Mary</option>
                    </select>
                </div>
                <div className="col-md-6 mb-2">
                    <label htmlFor="" className="form-label mb-2">Etrap/şäh</label>
                    <select name="" id="" className="form-select form-select-sm">
                        <option value="">...</option>
                        <option value="">Arkadag</option>
                        <option value="">Köpetdag</option>
                        <option value="">Bagtyýarlyk</option>
                    </select>
                </div>
                <div className="col-md-6 mb-2">
                    <label htmlFor="" className="form-label mb-2">Kesel görnüşi</label>
                    <div className="align-items-center justify-content-center flex-wrap">
                        {
                            epidsArr.map((epid)=>{
                                return(
                                    <Checkbox title={epid.title} key={`checkbox-${epid.value}`}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <label htmlFor="" className="form-label mb-2">Keselçilik parametri</label>
                    <div className="align-items-center justify-content-center flex-wrap">
                        {
                            opsArr.map((epid)=>{
                                return(
                                    <Checkbox title={epid.title} key={`checkbox-${epid.value}`}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <label htmlFor="" className="form-label mb-2">Hasabat döwri (<i>başy-soňy</i>)</label>
                    <div className="d-flex align-items-center justify-content-center">
                        <input type="date" name="" id="" className="form-control" />
                        <i className="bi bi-dash-lg mx-2"></i>
                        <input type="date" name="" id="" className="form-control" />
                    </div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="" className="form-label mb-2">Jynsy</label>
                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                        {
                            gendersArr.map((gender)=>{
                                return(
                                    <CheckableCard title={gender.title} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
         </Modal>
        </>
    )
}