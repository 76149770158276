import { useContext, useEffect, useState } from "react";
import AdminWrapper from "../../Wrapper";
import Form from "./form";
import { AuthContext } from "../../../../context/AuthContext";
import { useParams } from "react-router-dom";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../../utils/main";

export default function AdminUsersUpdate(){
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const {isReady, token} = useContext(AuthContext);
    const params = useParams();
    useEffect(()=>{
        if(isReady && params?.id != null){
            AxiosHelper.getUser(params.id, token).then((result)=>{
                if(result.status == 200){
                    setForm({...result.data.model});
                }
            }).catch((e)=>handleErrors(e));
        }
    }, [isReady, params?.id])
    return (
        <AdminWrapper>
            <Form errors={errors} setErrors={setErrors} form={form} setForm={setForm} isUpdate={true} key={'admin-users-form'} />
        </AdminWrapper>
    )
}