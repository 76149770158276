import { useState } from "react";
import AdminWrapper from "../Wrapper";
import Chart from "react-apexcharts";
import JumbotronButton from "../../../components/jumbotron-button/jumbotron-button";


export default function AdminSettings(){
    const items = [
        {icon:<img src="/img/epidemia.png" className="jumbotron-button-img" />, title:'Keselçilik', link:'/settings/epids'},
        {icon:<img src="/img/age.png" className="jumbotron-button-img" />, title:'Kesel attributlary', link:'/settings/epid-options'},
        {icon:<img src="/img/diagnose.png" className="jumbotron-button-img" />, title:'Kesel kesgidi', link:'/settings/diagnoses'},
        {icon:<img src="/img/regions.png" className="jumbotron-button-img" />, title:'Welaýatlar', link:'/settings/regions'},
        {icon:<img src="/img/villages.png" className="jumbotron-button-img" />, title:'Etraplar', link:'/settings/villages'},
        {icon:<img src="/img/office.png" className="jumbotron-button-img" />, title:'SES bölümler', link:'/settings/offiсes'},
        {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'Ulanyjylar', link:'/settings/users'},
        {icon:<img src="/img/document.png" className="jumbotron-button-img" />, title:'Ulanyjy rollary', link:'/settings/roles'},
        {icon:<img src="/img/chat.png" className="jumbotron-button-img" />, title:'Çat', link:'/settings/chat'},
        {icon:<img src="/img/users.png" className="jumbotron-button-img" />, title:'Raýatlar', link:'/settings/patients'},
    ];
    return (
        <AdminWrapper>
            <div className="d-flex align-items-center justify-content-between text-slatgray mb-3">
                <h5>Sazlamalar</h5>
            </div>
            <div class="row align-items-md-stretch justify-content-center">
                {
                    items.map((item)=>{
                        return (
                            <div class="col-md-4 mb-4">
                                <JumbotronButton icon={item?.icon} link={item?.link} title={item?.title} />
                            </div>
                        )
                    })
                }
            </div>
        </AdminWrapper>
    )
}