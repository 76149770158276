import { useContext, useEffect } from "react";
import AdminHeader from "./_components/header/header";
import AdminRightbar from "./_components/rightbar/_rightbar";
import AdminSidebar from "./_components/sidebar/sidebar";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function AdminWrapper({mainClass=null, ...props}){
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    console.log(location);
    useEffect(()=>{
        const exp = localStorage.getItem('expTime');
        if(!exp || exp*1 < new Date().getTime()){
            localStorage.removeItem('expTime')
            navigate('/login')
        }
    }, [location.pathname])
    return (
        <div className="admin-wrapper">
            <AdminHeader />
            <div className="d-flex">
                <AdminSidebar />
                <main className={"main "+mainClass}>
                    {props.children}
                </main>
                <AdminRightbar />
            </div>
        </div>
    )
}