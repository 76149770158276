import { useContext, useRef, useState } from "react";
import AuthWrapper from "./Wrapper";
import InputBlock from "../../components/input-block/input-block";
import { checkRequire } from "../../utils/main";
import AxiosHelper from "../../utils/API/AxiosHelper";
import { appendNotification } from "../../components/notification";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function Login(){
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const {login} = useContext(AuthContext);
    const formRef = useRef()
    const navigate = useNavigate();
    function submit(e){
        e?.preventDefault();
        if(!opts.isLoading){
            let isError = false;
            if(!checkRequire(form?.login)){
                setErrors({...errors, login:'Ulanyjy ady boş bolup bilmez!'})
                isError = true;
            }

            if(!checkRequire(form?.password)){
                setErrors({...errors, password:'Açar sözi boş bolup bilmez!'})
                isError = true;
            }
            if(!isError){
                if(form?.login == 'Admin' && form?.password == '2022-2023'){
                    localStorage.setItem('expTime', new Date().getTime()+5*60*1000);
                    navigate('/', {
                        state:{
                            token:new Date().getTime()
                        }
                    })
                }else{
                    appendNotification("Giriş maglumatlary nädogry", 'danger')
                }
            }
        }
    }
    const [opts, setOpts] = useState({isPassword:true, isLoading:false});
    return (
        <AuthWrapper>
            <span className="h5">Ulgama girmek</span>
            <span className="fst-italic text-small mb-3">Öz ulanyjy adyňyzy we açar sözüňizi giriziň</span>
            <form className="auth-form" onSubmit={(e)=>submit(e)} ref={formRef}>
                <InputBlock error={errors?.login} id={"loginUsername"} label={"Ulanyjy ady"} value={form?.login} setValue={(val)=>{
                    setErrors({...errors, login:''})
                    setForm({...form, login:val.trim()})
                }} icon={'bi bi-person'} placeholder="Ulanyjy ady..."/>
                <InputBlock error={errors?.password} id={"loginPassword"} label={"Açar sözi"} 
                    value={form?.password} setValue={(val)=>{
                        setErrors({...errors, password:''})
                        setForm({...form, password:val.trim()})
                    }} 
                    icon={opts.isPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}  placeholder="Açar sözi..." 
                    type={opts.isPassword ? 'password' : 'text'} onIconClick={()=>setOpts({...opts, isPassword:!opts.isPassword})}/>
                <div className="fst-italic text-small fw-500 text-themed text-end mb-4">Açar sözüni unutdyňyzmy?</div>
                <button className="btn btn-slat rounded-pill w-100">{
                    opts.isLoading ?
                    <div class="spinner-border spinner-border-sm">
                    </div>
                    :
                    'Ulgama gir'
                }</button>
            </form>
        </AuthWrapper>
    )
}