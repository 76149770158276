import { useEffect, useState } from "react"
import { addZero } from "../../utils/main";

export default function Timer({startTime, waitTime, onStop=()=>{}}){
    const [time, setTime] = useState('00:00');
    const [deadTime, setDeadTime] = useState(0);
    let timeout = null;
    useEffect(()=>{
        setDeadTime(startTime+waitTime);
        getRemainedTime();
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(resetTimeout, 1000);
    }, [startTime, waitTime])

    function getRemainedTime(){
        const total = deadTime-(new Date()).getTime();
        const minutes = Math.floor((total/1000/60)%60);
        const seconds = Math.floor((total/1000)%60);
        setTime(`${addZero(minutes)}:${addZero(seconds)}`);
        return {total, minutes, seconds};
    }

    function resetTimeout(){
        const {total} = getRemainedTime();
        if(total <= 0){
            clearTimeout(timeout);
            setTime('00:00')
            onStop();
        }else{
            setTimeout(resetTimeout, 1000);
        }
    }

    useEffect(()=>{
        // console.log(`st:${startTime}; \n wt:${waitTime};`);
        return clearTimeout(timeout);
    }, [])
    

    return (
        <span className="timer">{time}</span>
    )
}