import Checkbox from "../../../../components/checkbox/checkbox"
import { getStatusesArr, roles } from "../../../../utils/main";

export default function Filter({filter, setFilter, roles}){
    return (
        <div className="villages-filter d-grid px-2 text-small">
            <div className="d-flex">
                <div className="px-2 me-3">
                    <div className="fw-500 mb-2 text-nowrap">Statusy</div>
                    {
                        getStatusesArr().map((status)=>{
                            return (
                                <Checkbox title={status?.txt} id={'office-'+status?.value} wrapperOptions={{className:'mb-1 form-check'}}
                                    isChecked={filter.statuses.includes(status?.value)} 
                                    onChange={(e)=>{
                                        let arr = [...filter.statuses];
                                        if(e.target?.checked){
                                            arr.push(status?.value);
                                            setFilter({...filter, statuses:arr})
                                        }else{
                                            arr = arr.filter((_)=>_ != status?.value);
                                            setFilter({...filter, statuses:arr})
                                        }
                                    }}/>
                            )
                        })
                    }
                </div>
                <div className="px-2 me-2">
                    <div className="fw-500 mb-2 text-nowrap">Roly</div>
                    {
                        Object.keys(roles).map((key)=>{
                            let role = roles[key];
                            return (
                                <Checkbox title={role?.name} id={'role-'+role?.id} wrapperOptions={{className:'mb-1 form-check'}}
                                    isChecked={filter.roles.includes(role?.id)} 
                                    onChange={(e)=>{
                                        let arr = [...filter.roles];
                                        if(e.target?.checked){
                                            arr.push(role?.id);
                                            setFilter({...filter, roles:arr})
                                        }else{
                                            arr = arr.filter((_)=>_ != role?.id);
                                            setFilter({...filter, roles:arr})
                                        }
                                    }}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}