export default function NoResult({text="Maglumat ýok!", isRefreshable = false, refreshFunction = ()=>{}, ...props}){
    return (
        <div className="position-relative w-100 p-2 justify-content-center align-items-center text-center">
            <div className="d-flex align-items-center justify-content-center fs-6 p-3">
                <p className="text-center text-secondary mb-2">
                    <i className="bi bi-exclamation-triangle fs-1"></i>
                    <br/>
                        {text}
                    <br /> 
                    {
                        isRefreshable ?
                        <button className="btn btn-sm bg-accent-secondary text-secondary mt-3" onClick={refreshFunction}>
                            <i className="bi bi-arrow-repeat me-2"></i>
                            Täzeden synanyş
                        </button>
                        :
                        null
                    }
                </p>
                {
                    props.children
                }
            </div>
        </div>
    )
}