import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { gendersArr, passportSeries, professions } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
        <div className="row">
            <div className="col-md-6">
                <InputBlock error={errors?.name} id={'admin-patients-form-name'} label={'Ady'} 
                    value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                    icon={'bi bi-person'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.surname} id={'admin-patients-form-surname'} label={'Familiýasy'} 
                    value={form?.surname} setValue={(e)=>setForm({...form, surname:e})}
                    icon={'bi bi-person'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.second_name} id={'admin-patients-form-second_name'} label={'Atasynyň ady'} 
                    value={form?.second_name} setValue={(e)=>setForm({...form, second_name:e})}
                    icon={'bi bi-person'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.birth_date} id={'admin-patients-form-birth_date'} label={'Doglan güni'} 
                    value={form?.birth_date} setValue={(e)=>setForm({...form, birth_date:e})} type="date"/>
            </div>
            <div className="col-md-6">
                <InputWrapper error={errors?.ps_serial_key} id={'admin-patients-form-ps_serial_key'} label={'Passport seriýasy'}>
                    <select className="form-select" value={form?.ps_serial_key} onChange={(e)=>setForm({...form, ps_serial_key:e.target.value})}>
                        <option value="">...</option>
                        {
                            passportSeries.map((serialKey)=>{
                                return (
                                    <option value={serialKey}>{serialKey}</option>
                                )
                            })
                        }
                    </select>
                </InputWrapper>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.ps_number} id={'admin-patients-form-ps_number'} label={'Passport belgisi'} 
                    value={form?.ps_number} setValue={(e)=>setForm({...form, ps_number:e})} type="number"/>
            </div>
            <div className="col-md-6">
                <InputWrapper error={errors?.profession} id={'admin-patients-form-profession'} label={'Hünäri'}>
                    {
                        form?.profession === '0' || form?.profession === 0  ?
                        <input type="text" className="form-control mb-2" placeholder="Raýat hünäriniň ady..."  value={form?.profession_text} onChange={(e)=>setForm({...form, profession_text:e.target.value})}/>
                        :
                        null
                    }
                    <div className="d-flex align-items-baseline justify-content-center flex-wrap">
                    {
                        Object.keys(professions).map((key)=>{
                            return (
                                <CheckableCard title={professions[key]} isActive={form?.profession == key} 
                                    onClick={()=>setForm({...form, profession:key})}/>
                            )
                        })
                    }
                    </div>
                </InputWrapper>
            </div>
            <div className="col-md-6">
                <InputWrapper error={errors?.gender} id={'admin-patients-form-gender'} label={'Jynsy'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                    {
                        gendersArr.map((gender)=>{
                            return (
                                <CheckableCard title={
                                    <div className="d-grid">
                                        <img src={"/img/"+gender.img} className="w-100" />
                                        <span className="mt-1">{gender.title}</span>
                                    </div>
                                } isActive={form?.gender == gender.value} 
                                    onClick={()=>setForm({...form, gender:gender.value})}/>
                            )
                        })
                    }
                    </div>
                </InputWrapper>
            </div>
        </div>
        </form>
    )
}