
import { useState } from "react";
import AdminWrapper from "../Wrapper";
import Form from "./form";
export default function StatisticCreate(){
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({offices:[]});
    return (
        <AdminWrapper>
            <Form errors={errors} setErrors={setErrors} form={form} setForm={setForm} isUpdate={false} />
        </AdminWrapper>
    )
}