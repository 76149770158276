import { useContext, useEffect, useRef, useState } from "react";
import InputBlock from "../../components/input-block/input-block"
import AuthWrapper from "./Wrapper"
import { Link, useLocation, useNavigate } from "react-router-dom";
import AxiosHelper from "../../utils/API/AxiosHelper";
import { AuthContext } from "../../context/AuthContext";
import { appendNotification } from "../../components/notification";
import { checkRequire } from "../../utils/main";
import Timer from "../../components/timer/timer";

export default function ConfirmLogin(){
    const [opts, setOpts] = useState({isLoading:false, instructionText:''});
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [canResend, setCanResend] = useState(false)
    const {isReady, login} = useContext(AuthContext);
    const formRef = useRef();
    const location = useLocation();
    const phone = location.state?.phone;
    const navigate = useNavigate();
    const [isTimerEnable, setIsTimerEnable] = useState(true);

    function submit(e){
        e?.preventDefault();
        if(!opts.isLoading){
            let isError = false;
            if(!checkRequire(form?.code)){
                setErrors({...errors, code:'Tassyklaýyş kody boş bolup bilmez!'})
                isError = true;
            }

            if(!isError){
                AxiosHelper.confirm(form).then((result)=>{
                    if(result.status === 200){
                        login(result.data?.token, result.data?.user)
                    }
                }).catch((e)=>{
                    console.log(e);
                    appendNotification("Näsazlyk ýüze çykdy!")
                })
            }
        }
    }
    useEffect(()=>{
        setOpts({...opts, instructionText:`Biz +993 ${phone} belgä sms kodyny ugratdyk`})
        setForm({...form, phone_number:phone})
    }, [phone])

    useEffect(()=>{
        if(isReady && !phone){
            navigate('/login')
        }
    }, [isReady, phone])
    return (
        <AuthWrapper>
            <span className="h5">Girişi tassyklamak</span>
            <span className="fst-italic text-small mb-3">{opts.instructionText}</span>
            <form className="auth-form mb-3" onSubmit={(e)=>submit(e)} ref={formRef}>
                <InputBlock error={errors?.code} id={"loginCode"} label={"Tassyklaýyş kody"} type="number" value={form?.code} setValue={(val)=>{
                    setErrors({...errors, code:''})
                    setForm({...form, code:val.trim()})
                }} icon={'bi bi-envelope'} placeholder="*****"/>

                <button className="btn btn-slat rounded-pill w-100">{
                    opts.isLoading ?
                    <div class="spinner-border spinner-border-sm">
                    </div>
                    :
                    'Tassyklamak'
                }</button>
            </form>
                <p className="text-center fw-lighter text-secondary text-small">
            {
                isTimerEnable ?
                !canResend ?
                <span className="text-secondary">
                    Gaýtadan iber:<Timer startTime={new Date().getTime()}  waitTime={5*60*1000} onStop={()=>setCanResend(true)}/>
                </span>
                :
                <span className="fw-500 text-primary cursor-pointer" onClick={()=>{
                    AxiosHelper.resendMessage(phone).then((result)=>{
                        if(result.status == 200){
                            appendNotification('Tassyklaýyş kody gaýtadan iberildi!', 'success')
                            setIsTimerEnable(false)
                        }
                    }).catch((e)=>{
                        console.error(e);
                        appendNotification('Näsazlyk ýüze çykdy!', 'danger')
                    })
                }}>
                    Gaýtadan iber
                </span>
                :
                <Link to={'/login'} className="fw-500 text-primary">
                    Gaýtadan synanyşmak
                </Link> 
            }
                </p>
        </AuthWrapper>
    )
}