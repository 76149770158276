import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../utils/main";
import StatisticsDiagram from "./diagram";

export default function SummTable({data, epids, opts}){
    const [map, setMap] = useState({});
    useEffect(()=>{
        let obj = {};
        Array.from(data).map((record)=>{
            let _obj = obj[record?.epid];
            if (!_obj) _obj= {};
            let oldVal = _obj[record?.option];
            if(!oldVal) oldVal = 0;
            _obj[record?.option] = oldVal+record?.count;
            obj[record?.epid] = _obj;
        });
        setMap(obj);
    }, [data]);
    return (
        <div className="row">
            <div className="col-md-12">
                {/* <StatisticsDiagram map={map} epids={epids}/> */}
            </div>
            <div className="col-md-12">
                <div className="rounded-3 mb-3 overflow-hidden border border-1 border-primary">
                    <table className="table table-border table-hover text-small mb-0 fw-bold">
                        <thead className="bg-accent-primary text-primary">
                            <tr>
                                <th style={{width:150+'px'}} className="text-center border-end border-primary">-</th>
                                {
                                    Object.keys(opts).map((key)=>{
                                        return (
                                            <th style={{width:100+'px'}} className="text-center border-end border-primary">
                                                {opts[key]?.name}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                
                                Object.keys(map).map((mapKey)=>{
                                    const optKeys = Object.keys(opts)
                                    const epid = map[mapKey];
                                    return (
                                        <tr>
                                            <td className="text-primary bg-accent-primary border-primary">
                                                {epids[mapKey]?.name}
                                            </td>
                                            {
                                                optKeys.map((opt)=>{
                                                    return (
                                                        <td className={`text-center ${epid[opt] > 0 ? 'bg-accent-danger text-danger border border-danger' : 'bg-accent-gray text-secondary border border-secondary'}`}>
                                                            {epid[opt] ? epid[opt] : 0}
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}