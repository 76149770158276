export default function ProfileItem(){
    return (
        <div className="profile-item">
            {/* <img src="/img/profile-img.jpg" alt="" /> */}
            <div className="img-placeholder">
                <span>
                    <i className="bi bi-person"></i>
                </span>
            </div>
            <span>A.Atayew</span>
        </div>
    )
}