import {useCallback, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'

export default function FileDropzone({accept, maxFiles}) {
  const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject} = useDropzone({onDrop, accept, maxFiles})

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <div {...getRootProps({style})}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p className='text-center'>Faýly ýüklemek ...</p> :
          <p className='text-center'>Ýüklemek isleýän faýlyňyzy bu ýere getiriň ýa-da bu meýdança basyň</p>
      }
    </div>
  )
}