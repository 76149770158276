import { Link } from "react-router-dom";

export default function GlobalNavs(){
    const navs = [
        {text:"Dogulyş", link:'/baby-registration'},
        {text:"Ýogalmak", link:'/die-registration'},
        {text:"Bellige almak", link:'/person-registration'},
        // {text:"Şahsy belgi", link:'/person-identificator'},
        {text:"Nika baglaşmak", link:'/marriage'},
        {text:"Nika bozmak", link:'/marriage-broke'},
        {text:"Hossarlyga almak", link:'/baby-care'},
        {text:"At üýtgetmek", link:'/change-name'},
        {text:"Atalygy tassyklamak", link:'/commit-parent'},
    ];
    return (
        <ul className="global-navs">
            {
                navs.map((nav)=>{
                    return (
                        <Link to={nav.link} className="global-navs-item">
                            {nav.text}
                        </Link>
                    )
                })
            }
        </ul>
    )
}