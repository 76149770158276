import { useContext, useEffect, useState } from "react";
import FormStep from "../../../components/form-step/step";
import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";
import Main from "./form-elements/main";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { handleErrors } from "../../../utils/main";

export default function PersonifiedCreate(){
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [offices, setOffices] = useState([]);
    const [officesArr, setOfficesArr] = useState([]);
    const [optsArr, setOptsArr] = useState([]);
    const {isReady, token, logout} =  useContext(AuthContext);
    useEffect(()=>{
        let arr = [];
        offices.map((office)=>{
            if(form?.region == office?.region){
                if(form?.village && form?.village == office?.village){
                    arr.push(office);
                }else if(form?.village == 0 && office?.village == null){
                    arr.push(office);
                }
            }else if(form.region == 0 && office.region == null){
                arr.push(office);
            }
        })
        setOfficesArr(arr);
    }, [offices, form?.region, form?.village])
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    setRegions(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    setVillages(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
            AxiosHelper.getOffices({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    setOffices(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
        }
    }, [isReady])
    return(
        <AdminWrapper>
            <PageTitle title={'Raýatlaýyn ýazgy goşmak'}  endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-danger btn-sm px-3 me-2">
                        <i className="bi bi-x me-2"></i>
                        Ret etmek
                    </button>
                    <button className="btn btn-slat btn-sm px-3">
                        <i className="bi bi-check2-circle me-2"></i>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            <div className="steps">
                <FormStep id={'personified-record-main-block'} stepName={"Ädim 1"} isActive={true} 
                    title={"Esasy maglumatlart"} content={
                        <Main errors={errors} setErrors={setErrors} form={form} setForm={setForm} regions={regions} villages={villages} offices={offices} officesArr={officesArr}/>
                    }/>
            </div>
        </AdminWrapper>
    )
}