import { useEffect, useState } from "react";
import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import InputBlock from "../../../../components/input-block/input-block";

export default function OfficeElement({form, setForm, errors, epids, opts, office, officeKey}){
    return (
        <div className="row justify-content-center">
            {
                Object.keys(form.offices[officeKey]).map((optKey)=>{
                    let opt = opts[optKey];
                    return(
                        <div className="col-md-3">
                            <InputBlock error={null} id={`statistic-record-office-${office?.id}-prop-${opt?.id}`} label={`${opt?.name}`}
                                value={form?.offices[officeKey][opt?.id] ? form?.offices[office?.id][opt?.id] : 0} 
                                setValue={
                                    (e)=>{
                                        let arr = {...form.offices};
                                        arr[officeKey] = {...arr[officeKey], [optKey]:e};
                                        setForm({...form, offices:{...arr}})
                                    }
                                }
                                type="number"
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}