import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import CheckableCard from '../../../../components/checkable-card/checkable-card';
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";

export default function AdminRolesForm({form, setForm, errors, saveFunction, permissions}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-roles-form-name'} label={'Ady'} 
            value={form?.name} setValue={(e)=>setForm({...form, name:e})}
            icon={'bi bi-keyboard'} />
            <InputWrapper  error={errors?.permissions} id={'admin-roles-form-premissions'} label={'Rugsatlar'}>
                <div className="d-flex flex-wrap align-items-baseline justify-content-center">
                    {
                        Array.from(permissions).map((permission)=>{
                            return (
                                <CheckableCard key={`admin-roles-permissions-card-${permission.code}`} title={permission?.title} 
                                    isActive={Array.from(form?.permissions).includes(permission.code)} 
                                    onClick={()=>{
                                        let arr = [...form.permissions];
                                        if(arr.includes(permission.code)){
                                            arr = arr.filter((_)=>_!=permission.code)
                                        }else{
                                            arr.push(permission.code)
                                        }
                                        setForm({...form, permissions:arr});
                                    }}/>
                            )
                        })
                    }
                </div>
            </InputWrapper>
        </form>
    )
}