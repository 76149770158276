import { Link } from "react-router-dom";
import Configurations from "../../../../config/configurations";
import { toggleSidebar } from "../../../../utils/main";

export default function AdminLogo(){
    return (
        <div className="d-flex align-items-center">
            <i className="bi bi-list me-2 fs-5 cursor-pointer" onClick={toggleSidebar}></i>
            <Link to="/" className="admin-logo">
                <img src="/img/logo.png" alt="logo img"/>
                <span>{Configurations.APPNAME}</span>
            </Link>
        </div>
    )
}