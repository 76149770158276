export default function OfficeElement(){
    return (
        <div className="row">
            <div class="col-md-12 mb-2">
                <div class="col-12">
                    <label for="inputNanme4" class="form-label">Ady</label>
                    <input type="text" class="form-control" id="inputNanme4"/>
                </div>
            </div>

            <div class="col-md-6 mb-2">
                <div class="col-12">
                    <label for="inputNanme4" class="form-label">Familiýasy</label>
                    <input type="text" class="form-control" id="inputNanme4"/>
                </div>
            </div>

            <div class="col-md-6 mb-2">
                <div class="col-12">
                    <label for="inputNanme4" class="form-label">Atasynyň ady</label>
                    <input type="text" class="form-control" id="inputNanme4"/>
                </div>
            </div>

            <div class="col-md-6 mb-2">
                <label for="inputState" class="form-label">Ýaşaýan welaýaty</label>
                <select id="inputState" class="form-select">
                    <option selected="">...</option>
                    <option>Aşgabat</option>
                    <option>Ahal</option>
                    <option>Balkan</option>
                </select>
            </div>

            <div class="col-md-6 mb-2">
                <label for="inputState" class="form-label">Ýaşaýan etraby</label>
                <select id="inputState" class="form-select">
                    <option selected="">...</option>
                    <option>Aşgabat</option>
                    <option>Ahal</option>
                    <option>Balkan</option>
                </select>
            </div>

            <div class="col-md-12 mb-2">
                <div class="col-12">
                    <label for="inputNanme4" class="form-label">Edaranyň ady</label>
                    <input type="text" class="form-control" id="inputNanme4"/>
                </div>
            </div>

            <div class="col-md-12 mb-2">
                <div class="col-12">
                    <label for="inputNanme4" class="form-label">Edaranyň salgysy</label>
                    <input type="text" class="form-control" id="inputNanme4"/>
                </div>
            </div>
        </div>
    )
}