import AdminWrapper from "../../pages/admin/Wrapper";
import FilterBlock from "../filter-block/filter-block";
import Loading from "../loading/Loading";
import NoResult from "../noResult/noResult";
import PageTitle from "../page-title/page-title";
import Pagination from "../pagination/pagination";
import Summary from "../summary/summary";
import AdminTable from "../table/table";

export default function SettingsBlock({
    filter, setFilter, startBlock, isLoading,
    data, tableItems, count, pageKey='', pageTitle='', isFilterable=false, filterBlock=null,
    pageTitleEndComponent=null, isRefreshable=false, refresh=()=>{}}){
    return (
        <AdminWrapper>
            <PageTitle title={pageTitle} endComponent={pageTitleEndComponent} />
            <div className="card p-2">
                <FilterBlock filter={filter} setFilter={setFilter} key={`${pageKey}-filter-block`} isRefreshable={isRefreshable} refresh={refresh}
                    filterBlock={filterBlock} isFilterable={isFilterable}
                    startBlock={startBlock}
                    />
                    {
                        isLoading ?
                        <Loading />
                        :
                        data && data?.length ?
                        <>
                        <AdminTable data={data} items={tableItems} key={`${pageKey}-table`} />
                        <div className="d-flex align-items-center justify-content-between px-2">
                            <Summary count={count} filter={filter} />
                            <Pagination filter={filter} setFilter={setFilter}  count={count} />
                        </div>
                        </>
                        :
                        <NoResult key={`${pageKey}-no-result-block`} isRefreshable={isRefreshable} refreshFunction={refresh}/>
                    }

                
            </div>
        </AdminWrapper>
    )
}