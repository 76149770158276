import { useContext, useEffect, useState } from "react";
import AdminWrapper from "../Wrapper";
import StatisticCreate from "./create";
import StatisticsDiagram from "./diagram";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { addZero, getString, handleErrors } from "../../../utils/main";
import { Link } from "react-router-dom";
import Sort from "../../../components/sort/sort";
import { appendNotification } from "../../../components/notification";
import AdminTable from "../../../components/table/table";
import Summary from "../../../components/summary/summary";
import Pagination from "../../../components/pagination/pagination";
import LimitSetter from "../../../components/limitSetter/limitSetter";
import Loading from "../../../components/loading/Loading";
import SummTable from "./summ-table";

export default function AdminStatistics(){
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState({});
    const [filter, setFilter] = useState({page:1, limit:20, date:(`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`)});
    const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [offices, setOffices] = useState([]);
    const [epids, setEpids] = useState([]);
    const [opts, setOpts] = useState([]);
    const {isReady, token, logout} = useContext(AuthContext);
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Sene" filter={filter} setFilter={setFilter} targetAttribute='date'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {item?.date}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Keselçilik" filter={filter} setFilter={setFilter} targetAttribute='epid'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-warning text-warning ms-2 fw-bold">
                        {`${getString(epids[item?.epid]?.name)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Parametr" filter={filter} setFilter={setFilter} targetAttribute='option'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-primary text-primary ms-2 fw-bold">
                        {opts[item?.option]?.name}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Sany" filter={filter} setFilter={setFilter} targetAttribute='count'/>, content:(item)=>{
                return (
                    <span className="badge bg-accent-danger text-danger ms-2 fw-bold">
                        {item?.count}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Welaýat" filter={filter} setFilter={setFilter} targetAttribute='region'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2">
                        {`${getString(regions[item?.region]?.short_name) ? getString(regions[item?.region]?.short_name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Etrap" filter={filter} setFilter={setFilter} targetAttribute='village'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2">
                        {`${getString(villages[item?.village]?.name) ? getString(villages[item?.village]?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Edara" filter={filter} setFilter={setFilter} targetAttribute='office'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2">
                        {`${getString(offices[item?.office]?.name)}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDate())}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu ýazgyny ýok etmekçimi?")){
                                AxiosHelper.deleteStatisticRecord(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Ýazgy üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <Link to={'/statistic/update/'+item?.id} className="btn btn-sm bg-accent-primary text-primary m-1">
                            <i className="bi bi-pencil"></i>
                        </Link>
                    </div>
                )
            }
        }
    ];
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))

            AxiosHelper.getOffices({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((office)=>{
                        obj[office?.id] = office;
                    })
                    setOffices(obj);
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))

            AxiosHelper.getEpids({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((epid)=>{
                        obj[epid?.id] = epid;
                    })
                    setEpids(obj)
                }
            }).catch((e)=>handleErrors(e, {}, ()=>{}, logout))
    
            AxiosHelper.getEpidOptions({limit:1000, sort:'start'}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((opt)=>{
                        obj[opt?.id] = opt;
                    })
                    setOpts(obj)
                }
            }).catch((e)=>handleErrors(e, {}, ()=>{}, logout))
        }
    }, [isReady])
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    function refresh(){
        if(isReady){
            setIsLoading(true)
            AxiosHelper.getStatisticRecords(filter, token).then((result)=>{
                if(result.status == 200){
                    setData(result.data?.data);
                    setCount(result.data?.count);
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
            .finally(()=>setIsLoading(false))
        }
    }
    return (
        <AdminWrapper>
            <div className="d-flex align-items-center justify-content-between text-slatgray mb-3">
                <h5>Statistiki ýazgylar</h5>
                <div className="d-flex align-items-center p-2 text-small">
                    <input type="date" name="statistic-records-report-date" id="statistic-records-report-date" className="form-control form-control-sm" 
                        value={filter.date} onChange={(e)=>{
                            setFilter({...filter, date:e.target.value})
                        }}
                    />
                </div>
            </div>
            <SummTable data={data} epids={epids} opts={opts}/>
            <div className="card p-2 rounded-2">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center cursor-pointer">
                        <Link to={'/statistic/create'} className="btn btn-primary btn-sm px-3">
                            <i className="bi bi-plus-lg me-2"></i>
                            Täze goşmak
                        </Link>
                    </div>
                    <div className="d-flex align-items-center">
                        <LimitSetter filter={filter} setFilter={setFilter} key={'statistic-records-limitsetter'} />
                        <button className="btn btn-light btn-sm me-2" onClick={refresh}>
                            <i className="bi bi-arrow-repeat"></i>
                        </button>
                        {/* <button className="btn btn-light btn-sm me-2">
                            <i className="bi bi-sliders"></i>
                        </button> */}
                    </div>
                </div>
                {
                    isLoading ?
                    <Loading/>
                    :
                    <>
                        <AdminTable data={data} items={items} key={'statistic-records'} isRefreshable={true} refreshFunction={refresh}/>
                        <div className="d-flex align-items-center justify-content-between px-2">
                            <Summary count={count} filter={filter} />
                            <Pagination filter={filter} setFilter={setFilter}  count={count} />
                        </div>
                    </>
                }
            </div>
        </AdminWrapper>
    )
}