import { Link } from "react-router-dom";
import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";

export default function ChangeName(){
    return (
        <AdminWrapper>
            <PageTitle title={"At üýtgetmek"} />
            
            <div class="row align-items-md-stretch">
                <div class="col-md-6 mb-4">
                    <div class="h-100 p-5 bg-accent-primary text-slatgray rounded-3 text-center cursor-pointer">
                        <img src="/img/certificate.png" height={150} />
                        <h5>At üýtgetmek üçin arzalar</h5>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="h-100 p-5 bg-accent-primary text-secondary border rounded-3 justify-content-center text-center cursor-pointer">
                        <img src="/img/change-name.png" height={150} className="mx-auto"/>
                        <h5>At üýtgetmegi hasaba almak</h5>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="h-100 p-5 bg-accent-primary text-secondary border rounded-3 justify-content-center text-center cursor-pointer">
                        <img src="/img/document.png" height={150} className="mx-auto"/>
                        <h5>At üýtgetmek barada kepilnama bermek</h5>
                    </div>
                </div>
                <div class="col-md-6 mb-4">
                    <div class="h-100 p-5 bg-accent-primary text-secondary border rounded-3 justify-content-center text-center cursor-pointer">
                        <img src="/img/marriage-reports.png" height={150} className="mx-auto"/>
                        <h5>At üýtgetmek boýunça hasabatlar</h5>
                    </div>
                </div>
            </div>
            

        </AdminWrapper>
    )
}