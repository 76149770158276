import FormStep from "../../../components/form-step/step";
import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";
import MainElement from "./form-elements/main";
import OfficeElement from "./form-elements/office";
import ParentsElement from "./form-elements/parents";

export default function AddBabyRegistration(){
    return (
        <AdminWrapper>
            <PageTitle title={"Çaga dogluşyny hasaba almak"} endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm px-2 btn-light me-2">
                        <i className="bi bi-x-lg me-2"></i>
                        Ret etmek
                    </button>
                    <button className="btn btn-sm px-2 bg-accent-primary text-primary">
                        <i className="bi bi-check2-circle me-2"></i>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            <div className="steps">
                <FormStep content={<MainElement />} id={"baby-registration-main-element"} stepName={"Ädim 1"} isActive={true} title={"Esasy maglumatlar"}/>
                <FormStep content={<ParentsElement/>} id={'baby-registration-parents-element'} stepName={"Ädim 2"} title={"Ene-atasy"} />
                <FormStep content={<OfficeElement/>} id={'baby-registration-office-element'} stepName={"Ädim 3"} title={"Habar beren edara"} />
            </div>
        </AdminWrapper>
    )
}