import NoResult from "../noResult/noResult"

export default function AdminTable({items, data, onDoubleClick=()=>{}, isRefreshable=false, refreshFunction=()=>{}}){
    return (
        data && data?.length ?
        <div className="rounded-3 overflow-hidden mb-2">
            <table class="table table-hover text-small mb-0">
                <thead className="bg-accent-primary text-primary">
                {
                    items ?
                    <tr>
                        {
                            Array.from(items).map((item)=>{
                                return (
                                    <th scope="col">{item?.text}</th>
                                )
                            })
                        }
                    </tr>
                    : 
                    <tr></tr>
                }
                </thead>
                {
                    <tbody>
                        {
                            Array.from(data).map((model, index)=>{
                                return (
                                    <tr className="align-items-center" onDoubleClick={()=>onDoubleClick(model)}>
                                        {
                                            items?.map((item)=>{
                                                return (
                                                    <td className="p-1">{item.content(model, index)}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                }

            </table>
        </div>
        :
        <NoResult isRefreshable={isRefreshable} refreshFunction={refreshFunction}/>
    )
}