import { useEffect, useState } from "react";
import CheckableCard from "../../../../../components/checkable-card/checkable-card";
import InputWrapper from "../../../../../components/input-wrapper/input-wrapper";

export default function OfficeElement({form, setForm, errors, offices, regions, villages}){
    const [officesArr, setOfficesArr] = useState([]);
    useEffect(()=>{
        let arr = [];
        Object.keys(offices).map((key)=>{
            let office = offices[key];
            if(form?.region == office?.region){
                if(form?.village && form?.village == office?.village){
                    arr.push(office);
                }else if(form?.village == 0 && office?.village == null){
                    arr.push(office);
                }
            }else if(form.region == 0 && office.region == null){
                arr.push(office);
            }
        })
        console.log(form);
        setOfficesArr(arr);
    }, [offices, form?.region, form?.village])
    return (
        <div className="row">
            <div className="col-md-12">
                <InputWrapper error={errors?.region} id={'admin-users-form-region'} label={"Welaýat"} key={'admin-users-form-region-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                        <CheckableCard title={'Döwlet derejesi'} isActive={form?.region == 0} onClick={()=>{
                            setForm({...form, region:0, village:0})
                        }} key={'admin-users-region-ceckable-card-'+0} />
                        {
                            Object.keys(regions).map((key)=>{
                                const region = regions[key]; 
                                return (
                                    <CheckableCard title={region.name} isActive={form?.region == region?.id} onClick={()=>{
                                        setForm({...form, region:region?.id, village:0})
                                    }} key={'admin-users-region-ceckable-card-'+region?.id} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
            {
                form?.region ?
                <div className="col-md-12">
                    <InputWrapper error={errors?.village} id={'admin-users-form-village'} label={"Etrap"} key={'admin-users-form-village-wrapper'}>
                        <div className="d-flex align-items-baseline justify-content-center">
                            <CheckableCard title={'Welaýat derejesi'} isActive={form?.village == 0} onClick={()=>{
                                setForm({...form, village:0})
                            }} key={'admin-users-village-ceckable-card-'+0} />
                            {
                                Object.keys(villages).map((key)=>{
                                    const village = villages[key];
                                    if(village?.region == form.region){
                                        return (
                                            <CheckableCard title={village?.name} isActive={form?.village == village?.id} onClick={()=>{
                                                setForm({...form, village:village?.id}) 
                                            }} key={'admin-users-village-ceckable-card-'+village?.id} />
                                        )
                                    }
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
                :
                null
            }
            <div className="col-md-12">
                <InputWrapper error={errors?.office} id={'admin-users-form-office'} label={"Degişli edarasy"} key={'admin-users-form-office-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                        {
                            officesArr.map((office)=>{
                                return (
                                    <CheckableCard title={office.name} isActive={form?.department == office?.id} onClick={()=>{
                                        setForm({...form, department:office?.id}) 
                                    }} key={'admin-users-department-ceckable-card-'+office?.id} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
        </div>
    )
}