import Checkbox from "../../../../components/checkbox/checkbox"

export default function RolesFilter({filter, setFilter, permissions}){
    return (
        <div className="roles-filter d-grid px-2 text-small" style={{width:200+'px'}}>
        {
            Array.from(permissions).map((permission)=>{
                return (
                    <Checkbox title={permission?.title} id={'permission-'+permission?.code} wrapperOptions={{className:'mb-1 form-check'}}
                        isChecked={filter.permissions.includes(permission?.code)} 
                        onChange={(e)=>{
                            let arr = [...filter.permissions];
                            if(e.target?.checked){
                                arr.push(permission.code);
                                setFilter({...filter, permissions:arr})
                            }else{
                                arr = arr.filter((_)=>_ != permission.code);
                                setFilter({...filter, permissions:arr})
                            }
                        }}/>
                )
            })
        }
        </div>
    )
}