import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";
import ReportsFilter from "./filter";

export default function AdminReports(){
    return (
        <AdminWrapper>
            <PageTitle title={"Hasabatlar"} endComponent={<ReportsFilter />}/>
        </AdminWrapper>
    )
}