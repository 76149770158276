export default function LimitSetter({filter, setFilter}){
    const limits = [1,10, 20, 30, 40, 50];
    return (
        <select name="" id="" class="form-control form control-sm text-main me-2" value={filter?.limit} style={{width:50+'px'}} onChange={(e)=>{
            setFilter({...filter, limit:e.target.value})
        }}>
            {
                limits.map((item)=>{
                    return(
                        <option value={item}>{item}</option>
                    )
                })
            }
        </select>
    )
}