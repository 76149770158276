export default function InputWrapper({id, label, error, ...props}){
    return (
        <div className="mb-2">
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="position-relative">
            {
                props.children
            }
            </div>
            <span className={`text-danger text-small px-2 ${error ? '' :'d-none'}`}>{error}</span>
        </div>
    )
}