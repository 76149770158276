import Checkbox from "../../../../components/checkbox/checkbox"

export default function Filter({filter, setFilter, regions}){
    return (
        <div className="villages-filter d-grid px-2 text-small" style={{width:200+'px'}}>
        {
            Object.keys(regions).map((key)=>{
                let region = regions[key];
                return (
                    <Checkbox title={region?.name} id={'region-'+region?.id} wrapperOptions={{className:'mb-1 form-check'}}
                        isChecked={filter.regions.includes(region?.id)} 
                        onChange={(e)=>{
                            let arr = [...filter.regions];
                            if(e.target?.checked){
                                arr.push(region?.id);
                                setFilter({...filter, regions:arr})
                            }else{
                                arr = arr.filter((_)=>_ != region?.id);
                                setFilter({...filter, regions:arr})
                            }
                        }}/>
                )
            })
        }
        </div>
    )
}