import { useState } from "react";
import AdminWrapper from "../Wrapper";
import Chart from "react-apexcharts";
import JumbotronButton from "../../../components/jumbotron-button/jumbotron-button";


export default function AdminDashboard(){
    const items = [
        // {image:"bi bi-grid", title:'Baş sahypa', link:'/'},
        {image:"baby.png", title:'Dogulyş', link:'/baby-registration', isActive:true},
        {image:"diagnose1.png", title:'Ýogalyş', link:'/die-registration', isActive:true},
        {image:"registration.png", title:'Bellige alma', link:'/person-registration', isActive:true},
        {image:"personal_identifcator.png", title:'Şahsy belgi', link:'/person-identificator', isActive:true},
        {image:"marriage.png", title:'Nika baglaşmak', link:'/marriage', isActive:true},
        {image:"divorce.png", title:'Nika bozmak', link:'/marriage-broke', isActive:true},
        {image:"baby-care.png", title:'Hossarlyga almak', link:'/baby-care', isActive:true},
        {image:"change-name.png", title:'At üýtgetmek', link:'/change-name', isActive:true},
        {image:"paternity.png", title:'Atalygy tassyklamak', link:'/commit-parent', isActive:true},
        {image:"documents.png", title:'Žurnallar', link:'/journals', isActive:true},
        {image:"reports.png", title:'Hasabatlar', link:'/reports'},
        // {image:"bi bi-receipt", title:'Kepilnamalar', link:'/certificates'},
        {image:"erdu.png", title:'ERDU', link:'/erdu'},
        {image:"library.png", title:'Kitaphana', link:'/library'},
        {image:"chat.png", title:'Çat', link:'/messenger'}
    ];
    // const items = [
    //     {icon:<img src="/img/baby.png" className="jumbotron-button-img" />, title:'Çaga dogulyşy', link:'/admin/baby-registration'},
    //     {icon:<img src="/img/diagnose1.png" className="jumbotron-button-img" />, title:'Ýogalyş', link:'/admin/die-registration'},
    //     {icon:<img src="/img/users.png" className="jumbotron-button-img" />, title:'Raýaty hasaba almak', link:'/admin/person-registartion'},
    //     {icon:<img src="/img/certificate.png" className="jumbotron-button-img" />, title:'Raýat şahsy belgisi', link:'/admin/person-identificator'},
    //     {icon:<img src="/img/marriage.png" className="jumbotron-button-img" />, title:'Nika baglaşmak', link:'/admin/wedding'},
    //     {icon:<img src="/img/certificate-wdding.png" className="jumbotron-button-img" />, title:'Nika bozmak', link:'/admin/wedding-restrict'},
    //     {icon:<img src="/img/baby-care.png" className="jumbotron-button-img" />, title:'Hossarlyga almak', link:'/admin/baby-care'},
    //     {icon:<img src="/img/user.png" className="jumbotron-button-img" />, title:'At üýtgetmek', link:'/admin/change-name'},
    //     {icon:<img src="/img/baby-certificate.png" className="jumbotron-button-img" />, title:'Atalygy tassyklamak', link:'/admin/commit-parent'},
    //     {icon:<img src="/img/reports.png" className="jumbotron-button-img" />, title:'Hasabatlar', link:'/admin/reports'},
    // ];
    return (
        <AdminWrapper>
            <div className="d-flex align-items-center justify-content-between text-slatgray mb-3">
                <h5>Baş sahypa</h5>
            </div>
            <div class="row align-items-md-stretch justify-content-center">
                {
                    items.map((item)=>{
                        return (
                            <div class="col-md-4 mb-4">
                                <JumbotronButton icon={<img src={`/img/${item?.image}`} className="jumbotron-button-img" />} link={item?.link} title={item?.title} />
                            </div>
                        )
                    })
                }
            </div>
        </AdminWrapper>
    )
}