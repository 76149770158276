import { useState } from "react";
import CheckableCard from "../../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../../components/input-block/input-block";
import InputWrapper from "../../../../../components/input-wrapper/input-wrapper";
import { getStatusesArr } from "../../../../../utils/main";

export default function MainElement({form, setForm, errors, roles}){
    const [isHide, setIsHide] = useState(true);
    return (
        <div className="row">
            <div className="col-md-6">
                <InputBlock error={errors?.login} id={'admin-users-form-login'} label={'Ulanyjy ady'} 
                    value={form?.login} setValue={(e)=>setForm({...form, login:e})}
                    icon={'bi bi-person'} />
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.password} id={'admin-users-form-password'} label={'Açar sözi'} 
                    value={form?.password} setValue={(e)=>setForm({...form, password:e})} type={isHide ? "password" : 'text'}
                    icon={isHide ? 'bi bi-eye' : 'bi bi-eye-slash'} onIconClick={()=>setIsHide(!isHide)}/>
            </div>
            <div className="col-md-12">
                <InputWrapper error={errors?.status} id={'admin-users-form-status'} label={"Statusy"} key={'admin-users-form-status-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                        {
                            getStatusesArr().map((status)=>{
                                return (
                                    <CheckableCard title={status.txt} isActive={form?.status == status?.value} onClick={()=>{
                                        setForm({...form, status:status?.value}) 
                                    }} key={'admin-users-status-ceckable-card-'+status.value} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
            <div className="col-md-12">
                <InputWrapper error={errors?.role} id={'admin-users-form-role'} label={"Roly"} key={'admin-users-form-role-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                        {
                            Object.keys(roles).map((key)=>{
                                const role = roles[key];
                                return (
                                    <CheckableCard title={role.name} isActive={form?.role == role?.id} onClick={()=>{
                                        setForm({...form, role:role?.id}) 
                                    }} key={'admin-users-role-ceckable-card-'+role?.id} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
        </div>
    )
}