import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import RolesFilter from "./filter";
import { addZero, checkOnlyLetters, checkRequire, getString, handleErrors } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import Form from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";
import Filter from "./filter";

export default function AdminVillages(){
    const [data, setData] = useState([]);
    const [regions, setRegions] = useState({});
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({name:''});
    const [errors, setErrors] = useState({name:null});
    const [filter, setFilter] = useState({page:1, limit:10, regions:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const {isReady, token, logout} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-villages-modal';
    const pageKey="admin-villages-page";
    const pageTitle="Etraplar";
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            })
        }
    }, [isReady])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Welaýat" filter={filter} setFilter={setFilter} targetAttribute='region'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(regions[item?.region]?.short_name) ? getString(regions[item?.region]?.short_name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDay())}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu etraby ýok etmekçimi?")){
                                AxiosHelper.deleteVillage(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Etrap üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getVillages(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, errors, setErrors, logout)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateVillage(form?.id, form, token) : AxiosHelper.createVillage(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Etrap üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Etrap ady boş bolup bilmez!'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Etrap ady diňe harplardan ybarat bolmaly!';
        }else{
            err.name = null;
        }

        if(!checkRequire(form?.region)){
            err.region='Etrapyň haýsy welaýata degişlidigini saýlaň!'
         }else if(!Object.keys(regions).includes(form?.region+'')){
             err.region='Etrapyň haýsy welaýata degişlidigini dogry saýlaň!';
         }else{
             err.region = null;
         }

         if(!checkRequire(form?.short_name)){
             err.short_name='Etrapyň gysga ady boş bolup bilmez!'
         }else if(!checkOnlyLetters(form?.short_name)){
             err.short_name='Etrapyň gysga ady diňe harplardan ybarat bolmaly!';
         }else if(form?.short_name?.length > 5){
             err.short_name='Etrapyň gysga ady 5 harpdan ybarat bolmaly!';
         }else{
             err.short_name = null;
         }

        setErrors({...errors, ...err})
        return err.name || err.short_name || err.region;
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={true} filterBlock={<Filter filter={filter} setFilter={setFilter} regions={regions} key={'admin-villages-filter'}/>}
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Etrap üýtgetmek' :"Täze etrap goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({name:'', short_name:'', region:''}); setErrors({})}}>
                    <Form form={form} setForm={setForm} errors={errors} setErrors={setErrors} saveFunction={save} regions={regions}/>
                </ModalWithButton>
            } />
    )
}