import InputBlock from "../../../../../components/input-block/input-block";
import InputGroupBlock from "../../../../../components/input-group-block/input-block";

export default function UserDataElement({form, setForm, errors, setErrors}){
    return (
        <div className="row">
            <div className="col-md-6">
                <InputBlock error={errors?.name} id={'admin-users-form-name'} label={'Ady'} 
                    value={form?.name} 
                    setValue={(e)=>{
                        setForm({...form, name:e.trim()})
                        setErrors({...errors, name:''})
                    }
                }/>
            </div>
            
            <div className="col-md-6">
                <InputBlock error={errors?.surname} id={'admin-users-form-surname'} label={'Familiýasy'} 
                    value={form?.surname} setValue={
                        (e)=>{
                            setForm({...form, surname:e.trim()})
                            setErrors({...errors, surname:''})
                        }
                    }/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.second_name} id={'admin-users-form-second_name'} label={'Atasynyň ady'} 
                    value={form?.second_name} setValue={
                        (e)=>{
                            setForm({...form, second_name:e.trim()})
                            setErrors({...errors, second_name:''})
                        }
                    }/>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.birth_date} id={'admin-users-form-date'} label={'Doglan güni'} 
                    value={form?.birth_date} type="date"
                    setValue={(e)=>{
                        setForm({...form, birth_date:e})
                        setErrors({...errors, birth_date:''})
                    }
                }/>
            </div>
            <div className="col-md-6">
                <InputGroupBlock error={errors?.phone_number} id={'admin-users-form-phone_number'} label={'Telefon belgisi'} 
                    value={form?.phone_number} setValue={
                        (e)=>{
                            setForm({...form, phone_number:e})
                            setErrors({...errors, phone_number:''})
                        }
                    } addon="+993" icon={'bi bi-phone'}/>
            </div>
            <div className="col-md-6">
                <InputGroupBlock error={errors?.email} id={'admin-users-form-email'} label={'E-poçta'} 
                    value={form?.email} setValue={
                        (e)=>{
                            setForm({...form, email:e})
                            setErrors({...errors, email:''})
                        }
                     } addon={<i className="bi bi-envelope"/>}/>
            </div>
        </div>
    )
}