export default function FormStep({id, title, stepName, content, isActive=false, titleIcon = 'bi bi-bookmark-plus'}){
    return (
        <div className={`step mb-3 ${isActive ? 'active' : ''}`} id={id}>
            <div className="indicator">
                <div className="d-flex align-items-center justify-content-center">
                    <button className="btn btn-dark btn-sm px-3">
                        {stepName}
                    </button>
                </div>
                <div className="line"></div>
            </div>
            <div className="content">
                <div className="step-header mb-2"  onClick={(e)=>{
                        let dom = document.getElementById(id);
                        dom.classList.toggle('active');
                    }}>
                    <div className="icon-container text-primary me-2">
                        <i className={titleIcon}></i>
                    </div>
                    {title}
                    <button className="btn ms-auto btn-sm">
                        <i className="bi bi-chevron-down toggler-btn"></i>
                    </button>
                </div>
                <div className="px-2">
                    {content}
                </div>
            </div>
        </div>
    )
}