import { useState } from "react"

export default function SearchInput({setFilter, filter}){
    const [text, setText] = useState('')
    return (
        <div className="w-100 position-relative px-3">
            <input type="text" className="form-control form-control-sm" placeholder="Gözleg..." value={text} 
                onChange={(e)=>setText(e.target.value)} onKeyDown={(e)=>{
                    if(e.code === 'Enter'){
                        setFilter({...filter, text})
                    }
                }}/>
            <i className="bi bi-search position-absolute end-0 top-50 translate-middle-y me-4" onClick={()=>setFilter({...filter, text})}></i>
        </div>
    )
}