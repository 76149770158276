export default class Configurations {
    // ----------------------------- VARIABLES -----------------------------

    /**
     * Application running mode 
     * It's must be in DEV or PUB 
     * DEV - for develeopment case
     * PUB - for publis case
     *  */ 
    static MODE = "PUB";

    /**
     * Hospital code for the application
     */
    static HOSPITAL_CODE = "AG-FIZ";

    /**
     * Company details for the displaying in application
     */
    static COMPANY_NAME = "Älem Tilsimat";
    static COMPANY_APPENDIX = "HJ";

    /**
     * Application shortname and fullname
     */
    static APPNAME = "RÝNÝ";
    static FULLAPPNAME = "Raýat ýagdaýy namalarynyň ýazgysy";
    static VERSION = "1.0";

    /**
     * Backend server for the application
     * devServer - uses for the development case
     * pubServer - uses for the publishment case
     */
    static devServer = "http://localhost:8810";
    static pubServer = "https://ses.alemtilsimat.com/api/";

    /**
     * Static server for the application
     * devStaticServer - uses for the development case
     * pubStaticServer - uses for the publishment case
     */
    static devStaticServer = "http://localhost:8810/api/static/";
    static pubStaticServer = "https://ses.alemtilsimat.com/api/static/";

    /**
     * Resources main path for the application
     */
    static devMainPath = "";
    static pubMainPath = "";

    /**
     * Axios timeout in milliseconds
     */
    static axiosTimeout = 15000;

    /**
     * Logout status code for the axios requests
     */
    static forbiddenStatus = 403;

    // ----------------------------- FUNCTIONS -----------------------------
    
    static getServer(mode){
        switch (mode) {
            case "DEV":
                return this.devServer;
                break;
            case "PUB":
                return this.pubServer;
                break;
            default:
                throw new Error("Application run mode isn't correct!")
                break;
        }
    }

    static getStaticServer(mode){
        switch (mode) {
            case "DEV":
                return this.devStaticServer;
                break;
            case "PUB":
                return this.pubStaticServer;
                break;
            default:
                throw new Error("Application run mode isn't correct!")
                break;
        }
    }

    static getMainPath(mode){
        switch (mode) {
            case "DEV":
                return this.devMainPath;
                break;
            case "PUB":
                return this.pubMainPath;
                break;
            default:
                throw new Error("Application run mode isn't correct!")
                break;
        }
    }
}