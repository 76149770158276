import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";
import { appendNotification } from "../../../../components/notification";
import RolesFilter from "./filter";
import { addZero, checkOnlyLetters, checkRequire, getString, handleErrors } from "../../../../utils/main";
import Sort from "../../../../components/sort/sort";
import ModalWithButton from "../../../../components/modal-button.jsx/modal-btn";
import AdminRolesForm from "./form";
import SettingsBlock from "../../../../components/settings-block/settings-block";

export default function AdminRoles(){
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [form, setForm] = useState({permissions:[], name:''});
    const [errors, setErrors] = useState({name:null, permissions:null});
    const [filter, setFilter] = useState({page:1, limit:10, permissions:[]});
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const {isReady, token, logout} = useContext(AuthContext);
    const closeRef = useRef();
    const modalId = 'admin-roles-modal';
    const pageKey="admin-roles-page";
    const pageTitle="Ulanyjy rollary";
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getPermissions(token).then((result)=>{
                if(result.status == 200){
                    setPermissions(result.data?.data)
                }
            }).catch((e)=>{
                handleErrors(e, errors, setErrors, logout)
            })
        }
    }, [isReady, token])
    useEffect(()=>{
        if(isReady){
            refresh()
        }
    }, [isReady, filter])
    const items = [
        {
            text:'#', content:(item, index)=>{
                return ((filter?.page-1)*filter?.limit)+index+1;
            }
        },
        {
            text:<Sort text="Ady" filter={filter} setFilter={setFilter} targetAttribute='name'/>, content:(item)=>{
                return (
                    <span className="text-dark ms-2 fw-bold">
                        {`${getString(item?.name) ? getString(item?.name) : '-//-'}`}
                    </span>
                )
            }
        },
        {
            text:<Sort text="Döredilen wagty" filter={filter} setFilter={setFilter} targetAttribute={"createdAt"}/>, content:(item)=>{
                const date = new Date(item?.createdAt);
                return (`${date.getFullYear()}-${addZero(date.getMonth()+1)}-${addZero(date.getDay())}`)
            }
        },
        {
            text:'', content:(item)=>{
                return (
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm bg-accent-danger text-danger m-1" onClick={()=>{
                            if(window.confirm("Siz hakykatdan hem bu roly ýok etmekçimi?")){
                                AxiosHelper.deleteRole(item?.id, token).then((result)=>{
                                    if(result.status == 200){
                                        appendNotification("Rol üstünlikli ýok edildi!")
                                        refresh()
                                    }
                                })
                            }
                        }}>
                            <i className="bi bi-trash2"></i>
                        </button>
                        <button className="btn btn-sm bg-accent-primary text-primary m-1" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={()=>{
                            setIsUpdate(true);
                            setForm({...item})
                        }}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                )
            }
        }
    ];
    function refresh(){
        setIsLoading(true);
        AxiosHelper.getRoles(filter, token).then((result)=>{
            if(result.status == 200){
                setData(result.data?.data);
                setCount(result.data?.count);
            }
        }).catch((e)=>{
            handleErrors(e, errors, setErrors, logout)
        }).finally(()=>setIsLoading(false));
    }
    function save() {
        if(isReady){
            let isError = validate();
            if(!isError){
                (isUpdate ? AxiosHelper.updateRole(form?.id, form, token) : AxiosHelper.createRole(form, token)).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Ulanyjy roly üstünlikli ${isUpdate ? 'üýtgedildi' : 'goşuldy'}`, 'success')
                        closeRef.current.click();
                        refresh();
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};
        if(!checkRequire(form?.name)){
           err.name='Ulanyjy rolunyň ady boş bolup bilmez!'
        }else if(!checkOnlyLetters(form?.name)){
            err.name='Ulanyjy rolunyň ady diňe harplardan ybarat bolmaly!';
        }else{
            err.name = null;
        }
        // if(!form.permissions.length){
        //     err.permissions='Ulanyjy rolunyň rugsatlary boş bolup bilmez!';
        // }else{
        //     err.permissions = null;
        // }
        setErrors({...errors, ...err})
        return err.name || err.permissions;
    }
    return (
        <SettingsBlock 
            count={count} data={data} filter={filter} setFilter={setFilter} isLoading={isLoading} 
            tableItems={items} isRefreshable={true} refresh={refresh}
            pageKey={pageKey} pageTitle={pageTitle} isFilterable={true}
            filterBlock={<RolesFilter permissions={permissions} filter={filter} setFilter={setFilter}/>}
            startBlock={
                <ModalWithButton modalId={modalId} title={isUpdate ? 'Ulanyjy roluny üýtgetmek' :"Täze ulanyjy roluny goşmak"}
                    isSaveEnable={true} saveFunction={save} closeRef={closeRef} onButtonClick={()=>{setIsUpdate(false); setForm({name:'', permissions:[]})}}>
                    <AdminRolesForm form={form} setForm={setForm} errors={errors} setErrors={setErrors} permissions={permissions} saveFunction={save}/>
                </ModalWithButton>
            } />
    )
}