import { useEffect, useState } from "react";

export default function Pagination({filter, setFilter, count}){
    const [max, setMax] = useState(0);
    useEffect(()=>{
        setMax(Math.ceil(count/filter?.limit));
    }, [count, filter?.limit])
    useEffect(()=>{
        if(max > 0 && filter.page > max){
            setFilter({...filter, page:1})
        }
    }, [max])
    function getMaxArray(max){
        let arr = [];
        for (let i = 0; i < max; i++) {
            arr.push(i);
        }
        return arr;
    }
    return (
        <div className="d-flex align-items-center">
            <ul class="pagination m-0">
                <li class="page-item">
                    <span class="page-link" aria-label="Previous" onClick={()=>{
                        setFilter({...filter, page:1})
                    }}>
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-double-left"></i>
                        </span>
                    </span>
                </li>
                <li class="page-item">
                    <span class="page-link" aria-label="Previous" onClick={()=>{
                        filter?.page > 1 &&
                        setFilter({...filter, page:filter.page-1})
                    }}>
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-left"></i>
                        </span>
                    </span>
                </li>
                {
                    getMaxArray(max).map((i)=>{
                        return (
                            <li class={"page-item "+(filter?.page == i+1 ? 'active' : '')} onClick={()=>setFilter({...filter, page:i+1})}>
                                <span class="page-link">{i+1}</span>
                            </li>
                        )
                    })
                }
                <li class="page-item">
                    <span class="page-link" aria-label="Previous" onClick={()=>{
                        filter?.page < max &&
                        setFilter({...filter, page:filter?.page+1})
                    }}>
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-right"></i>
                        </span>
                    </span>
                </li>
                <li class="page-item">
                    <span class="page-link" aria-label="Previous" onClick={()=>{
                        setFilter({...filter, page:max})
                    }}>
                        <span aria-hidden="true">
                            <i className="bi bi-chevron-double-right"></i>
                        </span>
                    </span>
                </li>
            </ul>
            {/* <i class="bi bi-chevron-double-left cursor-pointer" onClick={()=>{
                if(filter.page > 1){
                    setFilter({...filter, page:filter.page-1});
                }
            }}></i>
            <input type="number" class="form-control text-center text-main number-input" value={filter?.page} id="pagination-input" min="1" max={max} onChange={(e)=>{
                if(parseInt(e.target.value) <= max){
                    setFilter({...filter, page:e.target.value})
                }
            }}/>
            <i class="bi bi-chevron-double-right cursor-pointer" onClick={()=>{
                if(filter.page < max){
                    setFilter({...filter, page:filter.page+1});
                }
            }}></i> */}
        </div>
    )
}