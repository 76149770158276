import { Link } from "react-router-dom";
import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";
import JumbotronButton from "../../../components/jumbotron-button/jumbotron-button";
import Chart from "react-apexcharts";
import { useState } from "react";

export default function MarriageReports(){
    const [rentgenOptions, setRentgenOptions] = useState({
        options:{
            chart:{
                id:'apexchart-example-rentgen'
            },
            labels:['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust'],
            fill:{
                colors:['rgb(169, 169, 255)']
            }
        },
        xaxis:{
            type:'category',
            categories:['Ýanwar', 'Fewral', 'Mart', 'Aprel', 'Maý', 'Iýun', 'Iýul', 'Awgust']
        },
        series:[
            {
                name:'Raýatlaýyn ýazgylar',
                data:[35, 42, 29, 53, 38, 27, 32, 25]
            }
        ],
    });
    return (
        <AdminWrapper>
            <PageTitle title={"Nika boýunça hasabatlar"}  
                endComponent={
                    <select className="form-select form-select-sm rounded-pill px-3" style={{width:150+'px'}}>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                    </select>
                }/>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <div className="h-100 text-primary rounded-3 bg-light box-shadow text-decoration-none p-3 position-relative overflow-hidden">
                            <img src="/img/2.png" height={80} className="position-absolute top-50 end-0 translate-middle-y"/>
                            <span className="small text-slatgray fw-500">
                                Nika baglaşmak üçin arzalar
                            </span>
                            <br />
                            <p className="fs-3 fw-500 text-primary m-0 p-0">
                                147  485
                            </p>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="h-100 text-primary rounded-3 bg-light box-shadow text-decoration-none p-3 position-relative overflow-hidden">
                            <img src="/img/bridegroom.png" height={80} className="position-absolute top-50 end-0 translate-middle-y"/>
                            <span className="small text-slatgray fw-500">
                                Öýlenýänleriň ortaça ýaşy
                            </span>
                            <br />
                            <span className="fs-3 fw-500 text-primary">
                                24,5
                            </span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="h-100 text-primary rounded-3 bg-light box-shadow text-decoration-none p-3 position-relative overflow-hidden">
                            <img src="/img/bride.png" height={70} className="position-absolute top-50 end-0 translate-middle-y"/>
                            <span className="small text-slatgray fw-500">
                                Durmuşa çykýanlaryň ortaça ýaşy
                            </span>
                            <br />
                            <span className="fs-3 fw-500 text-primary">
                                23,8
                            </span>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="h-100 text-primary rounded-3 bg-light box-shadow text-decoration-none p-3 position-relative overflow-hidden">
                            <img src="/img/hearts.png" height={70} className="position-absolute top-50 end-0 translate-middle-y"/>
                            <span className="small text-slatgray fw-500">
                                Gaýtadan nikalaşýanlar
                            </span>
                            <br />
                            <span className="fs-3 fw-500 text-primary">
                                20%
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="z-index-5 card border-0 box-shadow p-3 bg-light rounded-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="fw-500 fs-5 text-primary">
                                <img src="/img/marriage.png" alt="" height={40} />
                                    Baglaşylan nikalar
                                </span>
                            </div>
                            <Chart options={rentgenOptions.options} series={rentgenOptions.series} chartOptions={rentgenOptions.chartOptions} type="bar" height={350}/>
                        </div>
                    </div>

                    {/* <div className="col-md-12 mb-3">
                        <div className="z-index-5 card border-0 box-shadow p-3 bg-light rounded-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="fw-500 fs-5 text-primary">
                                    Meşhur toý mekanlary
                                </span>
                                <select className="form-select form-select-sm rounded-pill px-3" style={{width:150+'px'}}>
                                    <option value="2023">Aşgabat</option>
                                    <option value="2022">Ahal</option>
                                    <option value="2021">Mary</option>
                                </select>
                            </div>
                            <hr className="my-2" />
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div> */}
                </div>


            
        </AdminWrapper>
    )
}