import { useContext, useEffect, useState } from "react";
import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { addZero, getStatusesArr, handleErrors } from "../../../../utils/main";
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from "../../../../utils/API/AxiosHelper";

export default function MainElement({form, setForm, errors, setErrors, offices, regions, villages, epids}){
    return (
        <div className="row">
            <div className="col-md-12">
                <InputWrapper error={errors?.epid} id={'admin-statistics-form-epid'} label={"Keselçilik görnüşi"} key={'admin-statistics-form-epid-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center flex-wrap">
                        {
                            epids.map((epid)=>{
                                return (
                                    <CheckableCard title={epid.name} isActive={form?.epid == epid?.id} onClick={()=>{
                                        setForm({...form, epid:epid?.id}) 
                                    }} key={'admin-statistics-epid-ceckable-card-'+epid?.id} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
            <div className="col-md-6">
                <InputBlock error={errors?.date} id={'admin-statistics-form-login'} label={'Hasabat senesi'} 
                    value={form?.date} setValue={(e)=>setForm({...form, date:e})} type="date" />
            </div>
            <div className="col-md-6">
                <InputBlock error={null} id={'admin-statistics-form-login'} label={'Bellige alyş senesi'} 
                    value={`${new Date().getFullYear()}-${addZero(new Date().getMonth()+1)}-${addZero(new Date().getDate())}`} setValue={()=>{}} type="date" inputProps={{disabled:true, className:'form-control bg-accent-gray'}}/>
            </div>
            <div className="col-md-12">
                <InputWrapper error={errors?.region} id={'admin-users-form-region'} label={"Welaýat"} key={'admin-users-form-region-wrapper'}>
                    <div className="d-flex align-items-baseline justify-content-center">
                        <CheckableCard title={'Döwlet derejesi'} isActive={form?.region == 0} onClick={()=>{
                            setForm({...form, region:0, village:0})
                        }} key={'admin-users-region-ceckable-card-'+0} />
                        {
                            Object.keys(regions).map((key)=>{
                                const region = regions[key]; 
                                return (
                                    <CheckableCard title={region.name} isActive={form?.region == region?.id} onClick={()=>{
                                        setForm({...form, region:region?.id, village:0})
                                    }} key={'admin-users-region-ceckable-card-'+region?.id} />
                                )
                            })
                        }
                    </div>
                </InputWrapper>
            </div>
            {
                form?.region ?
                <div className="col-md-12">
                    <InputWrapper error={errors?.village} id={'admin-users-form-village'} label={"Etrap"} key={'admin-users-form-village-wrapper'}>
                        <div className="d-flex align-items-baseline justify-content-center">
                            <CheckableCard title={'Welaýat derejesi'} isActive={form?.village == 0} onClick={()=>{
                                setForm({...form, village:0})
                            }} key={'admin-users-village-ceckable-card-'+0} />
                            {
                                Object.keys(villages).map((key)=>{
                                    const village = villages[key];
                                    if(village?.region == form.region){
                                        return (
                                            <CheckableCard title={village?.name} isActive={form?.village == village?.id} onClick={()=>{
                                                setForm({...form, village:village?.id}) 
                                            }} key={'admin-users-village-ceckable-card-'+village?.id} />
                                        )
                                    }
                                })
                            }
                        </div>
                    </InputWrapper>
                </div>
                :
                null
            }
        </div>
    )
}