import Checkbox from "../../../../components/checkbox/checkbox"
import { officeTypes } from "../../../../utils/main";

export default function Filter({filter, setFilter, regions}){
    return (
        <div className="villages-filter d-grid px-2 text-small">
            <div className="d-flex">
                <div className="px-2 me-3">
                    <div className="fw-500 mb-2 text-nowrap">Edara derejesi</div>
                    {
                        officeTypes.map((office)=>{
                            return (
                                <Checkbox title={office?.title} id={'office-'+office?.value} wrapperOptions={{className:'mb-1 form-check'}}
                                    isChecked={filter.types.includes(office?.value)} 
                                    onChange={(e)=>{
                                        let arr = [...filter.types];
                                        if(e.target?.checked){
                                            arr.push(office?.value);
                                            setFilter({...filter, types:arr})
                                        }else{
                                            arr = arr.filter((_)=>_ != office?.value);
                                            setFilter({...filter, types:arr})
                                        }
                                    }}/>
                            )
                        })
                    }
                </div>
                <div className="px-2 me-2">
                    <div className="fw-500 mb-2 text-nowrap">Welaýat</div>
                    {
                        Object.keys(regions).map((key)=>{
                            let region = regions[key];
                            return (
                                <Checkbox title={region?.name} id={'region-'+region?.id} wrapperOptions={{className:'mb-1 form-check'}}
                                    isChecked={filter.regions.includes(region?.id)} 
                                    onChange={(e)=>{
                                        let arr = [...filter.regions];
                                        if(e.target?.checked){
                                            arr.push(region?.id);
                                            setFilter({...filter, regions:arr})
                                        }else{
                                            arr = arr.filter((_)=>_ != region?.id);
                                            setFilter({...filter, regions:arr})
                                        }
                                    }}/>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}