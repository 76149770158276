import CheckableCard from "../../../../components/checkable-card/checkable-card";
import InputBlock from "../../../../components/input-block/input-block";
import InputWrapper from "../../../../components/input-wrapper/input-wrapper";
import { epidStatuses } from "../../../../utils/main";
export default function Form({form, setForm, errors, saveFunction}){
    return (
        <form onSubmit={(e)=>{
            e.preventDefault();
            saveFunction()
        }}>
            <InputBlock error={errors?.name} id={'admin-epids-form-name'} label={'Ady'} 
                value={form?.name} setValue={(e)=>setForm({...form, name:e})}
                icon={'bi bi-keyboard'} />
            <InputWrapper error={errors?.status} id={'admin-epids-form-status'} label={'Statusy'} key={'admin-epids-form-status-wrapper'}>
                <div className="d-flex flex-wrap justify-content-center">
                    {
                        epidStatuses.map((status)=>{
                            return (
                                <CheckableCard title={status.title} isActive={form?.status == status.value} 
                                    onClick={()=>setForm({...form, status:status.value})} key={'admin-epids-item-'+status.value}/>
                            )
                        })
                    }
                </div>
            </InputWrapper>
        </form>
    )
}