import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";

export default function Chat(){
    return(
        <AdminWrapper mainClass={'p-0'}>
            <div className="d-flex vh-100">
                <div className="chats-block border-end h-100 main-padding px-2">
                    <div className="d-flex align-items-center w-100 mb-3">
                        <div className="d-grid">
                            <span className="fw-500 fs-4">
                                Hatlar
                            </span>
                            <span className="fw-500 text-secondary text-small">
                                25 habar, 2 okalmadyk
                            </span>
                        </div>
                        <button className="btn btn-light btn-sm ms-auto">
                            <i className="bi bi-plus-lg"></i>
                        </button>
                    </div>
                    <div className="position-relative mb-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Gözleg..." style={{paddingLeft:30+'px'}}/>
                        <i className="bi bi-search position-absolute start-0 top-50 translate-middle-y ms-2 text-secondary fs-6"></i>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <div className="profile-item">
                            <div className="img-placeholder">
                                <span>
                                    Y
                                </span>
                            </div>
                            <div className="d-grid">
                                <span>Y.Atayew</span>
                                <span className="text-small fw-500 text-secondary">
                                    Mary SES
                                </span>
                            </div>
                        </div>
                        <div className="d-grid ms-auto">
                            <span className="fst-italic fw-lighter text-small mb-1">10 min</span>
                            <span className="badge bg-accent-primary text-primary">4</span>
                        </div>
                    </div>
                </div>
                <div className="w-100 position-relative border-end chat-content">
                    <div className="chat-owner border-bottom border-top">
                        <div className="profile-item">
                            <div className="img-placeholder">
                                <span>
                                    Y
                                </span>
                            </div>
                            <div className="d-grid">
                                <span>Y.Atayew</span>
                                <span className="text-small fw-500 text-secondary">
                                    Mary SES
                                </span>
                            </div>
                        </div>
                        <i className="bi bi-search ms-auto me-2"></i>
                    </div>
                    <div className="chat-send-block">
                        <div className="position-relative w-100">
                            <input type="text" className="form-control bg-transparent" />
                            <div className="position-absolute start-0 top-50 translate-middle-y ms-2 text-primary">
                                <i className="bi bi-paperclip"></i>
                            </div>
                            <button className="btn btn-primary position-absolute end-0 top-50 translate-middle-y me2 px-3 start-radius-0">
                                <i className="bi bi-send"></i>
                            </button>
                        </div>
                    </div>
                    <div className="chat-messages">
                        <div className="message">
                            <div className="message-buble">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente saepe sit possimus voluptate autem. Voluptates quasi, quo perferendis sapiente, voluptate facere eos ipsam quidem fuga officiis eum repudiandae at accusamus.
                                <div className="time">
                                    17:18
                                </div>
                            </div>
                        </div>
                        <div className="time-delimeter">
                            <div className="delimeter"></div>
                            <span className="time mx-auto">7 Okt, 2023</span>
                        </div>
                        <div className="message own">
                            <div className="message-buble">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente saepe sit possimus voluptate autem. Voluptates quasi, quo perferendis sapiente, voluptate facere eos ipsam quidem fuga officiis eum repudiandae at accusamus.
                                <div className="time">
                                    <i className="bi bi-check2-all me-2"></i>
                                    17:20 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}