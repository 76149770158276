import PageTitle from "../../../components/page-title/page-title";
import AdminWrapper from "../Wrapper";
import AddNewBook from "./create/create";

export default function Library(){
    return (
        <AdminWrapper>
            <PageTitle title={"Kitaphana"} endComponent={<AddNewBook/>}/>
            <div className="border border-1 p-2 rounded-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <select name="" id="" className="form-select form-select-sm" style={{maxWidth:60+'px'}}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select>
                    <div className="w-100 position-relative px-3">
                        <input type="text" className="form-control form-control-sm" placeholder="Gözleg..."/>
                        <i className="bi bi-keyboard position-absolute end-0 top-50 translate-middle-y me-4"></i>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn btn-sm btn-light me-2">
                            <i className="bi bi-sliders"></i>
                        </button>
                        <button className="btn btn-sm btn-light">
                            <i className="bi bi-grid"></i>
                        </button>
                    </div>
                </div>
                <div className="rounded-3 overflow-hidden mb-2">
                    <table className="table table-hover text-small m-0">
                        <thead>
                            <tr className="bg-accent-primary text-primary">
                                <th scope="col">#</th>
                                <th>Ady</th>
                                <th>Kategoriýa</th>
                                <th>Awtor</th>
                                <th>Goşulan senesi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Respirator keselleri bejermegiň esaslary</td>
                                <td>
                                    <span className="badge bg-accent-success text-success">
                                    Gollanmalar
                                    </span>
                                </td>
                                <td>A.Amanow</td>
                                <td>10/12/2023</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="d-flex align-items-center justify-content-between px-2">
                    <div className="summary">
                        1-20 / 120
                    </div>
                    <ul class="pagination mb-0">
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-left"></i></span>
                        </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link active" href="#">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i className="bi bi-chevron-double-right"></i></span>
                        </a>
                        </li>
                    </ul>
                </div>
            </div>
        </AdminWrapper>
    )
}