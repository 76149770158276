import { useContext, useEffect, useRef, useState } from "react"
import AutoSearchInput from "../../../../components/auto-search-input/input"
import { AuthContext } from "../../../../context/AuthContext";
import AxiosHelper from '../../../../utils/API/AxiosHelper';
import { closeModal, handleErrors } from "../../../../utils/main";
import { Link } from "react-router-dom";
export default function SearchUser({onDoubleClick=()=>{}}){
    const {isReady, token, logout} = useContext(AuthContext);
    const [text, setText] = useState('')
    const [data, setData] = useState([]);
    const closeRef = useRef();
    function search(){
        if(isReady){
            AxiosHelper.getPatients({text:text, limit:20, order:'surname'}, token).then((result)=>{
                if(result.status == 200){
                    setData(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, {}, ()=>{}, logout));
        }
    }
    useEffect(()=>{
        if(isReady){
            search()
        }
    }, [isReady])
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <span className="fs-5 fw-500 text-slatgray">
                    Ulanyjy saýlamak
                </span>
                <button className="btn btn-light" data-bs-dismiss="modal" ref={closeRef}>
                    <i className="bi bi-x-lg"></i>
                </button>
            </div>
            <hr className="my-2" /> 
            <div className="d-flex align-items-center w-100">
                <AutoSearchInput setText={setText} text={text} id="user-search-input" placeholder="..." runFunction={search}/>
                <Link to={'/settings/patients'} className="btn btn-primary small fs-6 text-nowrap ms-2">
                    <i className="bi bi-plus-lg me-2"></i>
                    Täze goşmak
                </Link>
            </div>
            <ul className="list-unstyled mt-2">
                {
                    data.map((patient)=>{
                        return(
                            <li className="border-bottom p-2 bg-accent-primary cursor-pointer" onDoubleClick={()=>{
                                onDoubleClick(patient);
                                closeRef.current.click()
                            }}>{patient?.surname}</li>
                        )
                    })
                }
            </ul>
        </>
    )
}