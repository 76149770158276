import GlobalNavs from "./_global_navs";
import LanguageSwitcher from "./_language_switcher";
import AdminLogo from "./_logo";
import ProfileItem from "./_profile";
import ThemeSwitcher from "./_theme_switcher";

export default function AdminHeader(){
    return (
        <header class="p-2 px-3 text-bg-light position-absolute top-0 start-0 end-0 w-100 border-bottom">
            <div class="d-flex flex-wrap align-items-center justify-content-between">
                <div className="col-md-3">
                    <AdminLogo />
                </div>
                <div className="col-md-6">
                    <GlobalNavs />
                </div>
                <div className="col-md-3">
                    <div class="d-flex align-items-center justify-content-end">
                        <ProfileItem />
                        {/* <LanguageSwitcher /> */}
                        {/* <ThemeSwitcher /> */}
                    </div>
                </div>

            </div>
        </header>
    )
}