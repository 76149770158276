import LimitSetter from '../limitSetter/limitSetter';
import SearchInput from '../search-input/search-input';
export default function FilterBlock({filter, setFilter, isLimitSetter=true, isSearchInput = true, 
        isFilterable = false, filterBlock = null, startBlock=null, isRefreshable = false, refresh=()=>{}}){
    return (
        <div className="d-flex align-items-center justify-content-between mb-3">
        {
            startBlock
        }
        {
            isSearchInput ?
            <SearchInput filter={filter} setFilter={setFilter} />
            :
            null
        }
            <div className="d-flex align-items-center">
                {
                    isLimitSetter ?
                    <LimitSetter filter={filter} setFilter={setFilter}/>
                    :
                    null
                }
                {
                    isRefreshable ?
                    <button class="btn btn-sm btn-light me-2" type="button" onClick={refresh}>
                        <i className="bi bi-arrow-repeat"></i>
                    </button>
                    :
                    null
                }
                {
                    isFilterable ?
                    <div class="dropdown">
                        <button class="btn btn-sm btn-light me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-sliders"></i>
                        </button>
                        <ul class="dropdown-menu">
                            {filterBlock}
                        </ul>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}