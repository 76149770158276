
import { useContext, useEffect, useState } from "react";
import AdminWrapper from "../Wrapper";
import Form from "./form";
import { AuthContext } from "../../../context/AuthContext";
import AxiosHelper from "../../../utils/API/AxiosHelper";
import { useNavigate, useParams } from "react-router-dom";
import { addZero, checkRequire, handleErrors } from "../../../utils/main";
import PageTitle from "../../../components/page-title/page-title";
import { appendNotification } from "../../../components/notification";
import InputWrapper from "../../../components/input-wrapper/input-wrapper";
import CheckableCard from "../../../components/checkable-card/checkable-card";
import InputBlock from "../../../components/input-block/input-block";
import FormStep from "../../../components/form-step/step";
export default function StatisticUpdate(){
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});const [regions, setRegions] = useState([]);
    const [villages, setVillages] = useState([]);
    const [epids, setEpids] = useState([]);
    const [opts, setOpts] = useState([]);
    const [optsArr, setOptsArr] = useState([]);
    const [offices, setOffices] = useState([]);
    const [officesArr, setOfficesArr] = useState([]);
    const {isReady, token, logout} = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams();
    useEffect(()=>{
        if(isReady && params?.id){
            AxiosHelper.getStatisticRecord(params?.id, token).then((result)=>{
                if(result.status == 200){
                    setForm(result.data?.model)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
        }
    }, [isReady, params?.id]) 
    useEffect(()=>{
        if(isReady){
            AxiosHelper.getRegions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((region)=>{
                        obj[region?.id] = region;
                    })
                    setRegions(obj);
                }
            }).catch((e)=>handleErrors(e))

            AxiosHelper.getVillages({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((village)=>{
                        obj[village?.id] = village;
                    })
                    setVillages(obj);
                }
            }).catch((e)=>handleErrors(e))
            AxiosHelper.getOffices({limit:10000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((office)=>{
                        obj[office?.id] = office;
                    })
                    setOffices(obj);
                }
            }).catch((e)=>handleErrors(e))
            AxiosHelper.getEpids({limit:1000}, token).then((result)=>{
                if(result.status == 200){ 
                    setEpids(result.data?.data)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))

            AxiosHelper.getEpidOptions({limit:1000}, token).then((result)=>{
                if(result.status == 200){
                    let obj = {};
                    Array.from(result.data?.data).map((opt)=>{
                        obj[opt?.id] = opt;
                    })
                    setOpts(obj)
                }
            }).catch((e)=>handleErrors(e, errors, setErrors, logout))
        }
    }, [isReady])
    function save(){
        if(isReady){
            let isError = validate();
            if(!isError){
                AxiosHelper.updateStatisticRecord(form?.id, form, token).then((result)=>{
                    if(result.status === 200){
                        appendNotification(`Statistiki ýazgy üstünlikli üýtgedildi`, 'success')
                        navigate('/statistic')
                    }
                }).catch((e)=>{
                    handleErrors(e, errors, setErrors, logout)
                })
            }else{
                appendNotification('Ýalňyşlyklary düzedip täzeden synanyşyň!', 'danger')
            }
        }
    }
    function validate(){
        let err = {};

        if(!checkRequire(form?.date)){
           err.date='Hasabat senesini giriziň'
        }else{
            err.date = null;
        }

        if(!checkRequire(form?.epid)){
           err.epid='Keselçilik görnüşini saýlaň'
        }else{
            err.epid = null;
        }
        setErrors({...errors, ...err})
        return err.date || err.epid;
    }
    useEffect(()=>{
        let arr = [];
        Object.keys(opts).map((optKey)=>{
            let opt = opts[optKey];
            if(opt.epids?.includes(form?.epid)){
                arr.push(opt);
            }
        })
        setOptsArr(arr);
    }, [form?.epid])
    useEffect(()=>{
        let arr = [];
        Object.keys(offices).map((key)=>{
            let office = offices[key];
            if(form?.region == office?.region){
                if(form?.village && form?.village == office?.village){
                    arr.push(office);
                }else if(form?.village == 0 && office?.village == null){
                    arr.push(office);
                }
            }else if(form.region == 0 && office.region == null){
                arr.push(office);
            }
        })
        setOfficesArr(arr);
    }, [offices, form?.region, form?.village])
    return (
        <AdminWrapper>
            <PageTitle title={"Statistiki ýazgyny üýtgetmek"} endComponent={
                <div className="d-flex align-items-center">
                    <button className="btn btn-sm btn-light px-2 me-2" onClick={()=>navigate('/statistic')}>
                        <i className="bi bi-x-lg me-2"></i>
                        Ret etmek
                    </button>
                    <button className="btn btn-sm bg-accent-primary text-primary px-2" onClick={save}>
                        <i className="bi bi-check2-square me-2"></i>
                        Ýatda saklamak
                    </button>
                </div>
            }/>
            <div className="container">
                <div className="row border rounded-3 p-2">
                    <div className="col-md-6">
                        <InputBlock error={errors?.date} id={'admin-statistics-form-date'} label={'Hasabat senesi'} 
                            value={form?.date} setValue={(e)=>setForm({...form, date:e})} type="date" />
                    </div>
                    <div className="col-md-6">
                        <InputBlock error={null} id={'admin-statistics-form-count'} label={'Ýüze çykan sany'} 
                            value={form?.count} setValue={(e)=>setForm({...form, count:e})} type="number"/>
                    </div>
                    <div className="col-md-12">
                        <InputWrapper error={errors?.epid} id={'admin-statistics-form-epid'} label={"Keselçilik görnüşi"} key={'admin-statistics-form-epid-wrapper'}>
                            <div className="d-flex align-items-baseline justify-content-center flex-wrap">
                                {
                                    epids.map((epid)=>{
                                        return (
                                            <CheckableCard title={epid.name} isActive={form?.epid == epid?.id} onClick={()=>{
                                                setForm({...form, epid:epid?.id}) 
                                            }} key={'admin-statistics-epid-ceckable-card-'+epid?.id} />
                                        )
                                    })
                                }
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col-md-12">
                        <InputWrapper error={errors?.option} id={'admin-statistics-form-option'} label={"Keselçilik parametri"} key={'admin-statistics-form-option-wrapper'}>
                            <div className="d-flex align-items-baseline justify-content-center flex-wrap">
                                {
                                    optsArr.map((option)=>{
                                        return (
                                            <CheckableCard title={option.name} isActive={form?.option == option?.id} onClick={()=>{
                                                setForm({...form, option:option?.id}) 
                                            }} key={'admin-statistics-option-checkable-card-'+option?.id} />
                                        )
                                    })
                                }
                            </div>
                        </InputWrapper>
                    </div>
                    <div className="col-md-12">
                        <InputWrapper error={errors?.region} id={'admin-users-form-region'} label={"Welaýat"} key={'admin-users-form-region-wrapper'}>
                            <div className="d-flex align-items-baseline justify-content-center">
                                <CheckableCard title={'Döwlet derejesi'} isActive={form?.region == 0} onClick={()=>{
                                    setForm({...form, region:0, village:0})
                                }} key={'admin-users-region-ceckable-card-'+0} />
                                {
                                    Object.keys(regions).map((key)=>{
                                        const region = regions[key]; 
                                        return (
                                            <CheckableCard title={region.name} isActive={form?.region == region?.id} onClick={()=>{
                                                setForm({...form, region:region?.id, village:0})
                                            }} key={'admin-users-region-ceckable-card-'+region?.id} />
                                        )
                                    })
                                }
                            </div>
                        </InputWrapper>
                    </div>
                    {
                        form?.region ?
                        <div className="col-md-12">
                            <InputWrapper error={errors?.village} id={'admin-users-form-village'} label={"Etrap"} key={'admin-users-form-village-wrapper'}>
                                <div className="d-flex align-items-baseline justify-content-center">
                                    <CheckableCard title={'Welaýat derejesi'} isActive={form?.village == 0} onClick={()=>{
                                        setForm({...form, village:0})
                                    }} key={'admin-users-village-ceckable-card-'+0} />
                                    {
                                        Object.keys(villages).map((key)=>{
                                            const village = villages[key];
                                            if(village?.region == form.region){
                                                return (
                                                    <CheckableCard title={village?.name} isActive={form?.village == village?.id} onClick={()=>{
                                                        setForm({...form, village:village?.id}) 
                                                    }} key={'admin-users-village-ceckable-card-'+village?.id} />
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </InputWrapper>
                        </div>
                        :
                        null
                    }
                    <div className="col-md-12">
                        <InputWrapper error={errors?.office} id={'admin-users-form-office'} label={"Degişli edarasy"} key={'admin-users-form-office-wrapper'}>
                            <div className="d-flex align-items-baseline justify-content-center">
                                {
                                    officesArr.map((office)=>{
                                        return (
                                            <CheckableCard title={office.name} isActive={form?.office == office?.id} onClick={()=>{
                                                setForm({...form, office:office?.id}) 
                                            }} key={'admin-users-office-ceckable-card-'+office?.id} />
                                        )
                                    })
                                }
                            </div>
                        </InputWrapper>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}